import produce from 'immer' // Import immer untuk membantu dalam mengubah state
import { create } from 'zustand'

interface SidebarState {
  isOpen: boolean
  toggleSidebar: () => void
}

export const useSidebarStore = create<SidebarState>((set) => ({
  isOpen: true, // Default state sidebar ditutup
  toggleSidebar: () =>
    set(
      produce((state: SidebarState) => {
        state.isOpen = !state.isOpen // Toggle state sidebar menggunakan immer
      })
    ),
}))
