export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Upload: any
}

export enum AccessControl {
  C = 'C',
  D = 'D',
  R = 'R',
  U = 'U',
}

export type AccessHistoryData = {
  date?: Maybe<Scalars['String']>
  imei?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  user?: Maybe<Scalars['String']>
  vinNo?: Maybe<Scalars['String']>
}

export type AccessHistoryParam = {
  endTime: Scalars['String']
  pagination?: InputMaybe<PaginationParam>
  search?: InputMaybe<Scalars['String']>
  startTime: Scalars['String']
  type?: InputMaybe<AccessType>
}

export type AccessHistoryResponse = {
  data?: Maybe<Array<Maybe<AccessHistoryData>>>
  lastUpdated?: Maybe<Scalars['String']>
  pagination?: Maybe<PaginationResult>
}

export enum AccessType {
  Asset = 'ASSET',
  Command = 'COMMAND',
  Emergency = 'EMERGENCY',
  History = 'HISTORY',
}

export enum AllOffsetsTimeZone {
  Gmt_0 = 'GMT_0',
  Gmt_1 = 'GMT_1',
  Gmt_2 = 'GMT_2',
  Gmt_3 = 'GMT_3',
  Gmt_4 = 'GMT_4',
  Gmt_5 = 'GMT_5',
  Gmt_6 = 'GMT_6',
  Gmt_7 = 'GMT_7',
  Gmt_8 = 'GMT_8',
  Gmt_9 = 'GMT_9',
  Gmt_10 = 'GMT_10',
  Gmt_11 = 'GMT_11',
  Gmt_12 = 'GMT_12',
  Gmt_13 = 'GMT_13',
  Gmt_14 = 'GMT_14',
}

export type AreaListResponse = {
  data: Array<Location>
  lastUpdated?: Maybe<Scalars['String']>
  pagination?: Maybe<PaginationResult>
}

export type AreaParam = {
  area: PolygonGeoJsonInput
  areaCode: Scalars['String']
  areaID?: InputMaybe<Scalars['String']>
  enabled: Scalars['Boolean']
  imageUrl?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  siteID?: InputMaybe<Scalars['String']>
}

export type AssetData = {
  ID?: Maybe<Scalars['String']>
  active?: Maybe<Scalars['Boolean']>
  capacity?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  createdBy?: Maybe<Scalars['String']>
  deviceID?: Maybe<Scalars['String']>
  enabled?: Maybe<Scalars['Boolean']>
  fleetModels?: Maybe<Scalars['String']>
  fleetName?: Maybe<Scalars['String']>
  imei?: Maybe<Scalars['String']>
  locationID?: Maybe<Scalars['String']>
  manufacture?: Maybe<Scalars['String']>
  modifiedAt?: Maybe<Scalars['String']>
  modifiedBy?: Maybe<Scalars['String']>
  tenantID?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  typeID?: Maybe<Scalars['String']>
}

export type AssetDettailResponse = {
  data?: Maybe<AssetData>
}

export type AssetListParams = {
  locationID?: InputMaybe<Scalars['String']>
  pagination?: InputMaybe<PaginationParam>
  search?: InputMaybe<Scalars['String']>
}

export type AssetListResponse = {
  data: Array<AssetData>
  lastUpdate?: Maybe<Scalars['String']>
  pagination?: Maybe<PaginationResult>
}

export type AssetModels = {
  ID?: Maybe<Scalars['String']>
  active?: Maybe<Scalars['Boolean']>
  brand?: Maybe<Scalars['String']>
  capacity?: Maybe<Scalars['String']>
  fleetTypeID?: Maybe<Scalars['String']>
  model?: Maybe<Scalars['String']>
}

export type AssetModelsResponse = {
  data: Array<AssetModels>
}

export type AssetParamsInsert = {
  deviceID?: InputMaybe<Scalars['String']>
  enabled?: InputMaybe<Scalars['Boolean']>
  fleetModels?: InputMaybe<Scalars['String']>
  fleetName?: InputMaybe<Scalars['String']>
  licensePlate?: InputMaybe<Scalars['String']>
  locationID?: InputMaybe<Scalars['String']>
}

export type AssetParamsUpdate = {
  ID?: InputMaybe<Scalars['String']>
  deviceID?: InputMaybe<Scalars['String']>
  enabled?: InputMaybe<Scalars['Boolean']>
  fleetModels?: InputMaybe<Scalars['String']>
  fleetName?: InputMaybe<Scalars['String']>
  licensePlate?: InputMaybe<Scalars['String']>
  locationID?: InputMaybe<Scalars['String']>
}

export type AssignDevicesParam = {
  ID: Scalars['String']
  fleetID: Scalars['String']
}

export type AssignDevicesResponse = {
  ID: Scalars['String']
}

export type AssignMaterialByAreaParam = {
  areaID: Scalars['String']
  endDate?: InputMaybe<Scalars['String']>
  materialID: Scalars['String']
  remarks: Scalars['String']
  siteID: Scalars['String']
  startDate: Scalars['String']
}

export type AssignMaterialParam = {
  endDate?: InputMaybe<Scalars['String']>
  fleetID: Scalars['String']
  materialID: Scalars['String']
  remarks: Scalars['String']
  siteID: Scalars['String']
  startDate: Scalars['String']
}

export type AssignmentInput = {
  geofenceIds: Array<Scalars['String']>
  orgId: Scalars['String']
}

export type BatteryData = {
  batteryConsumption: Scalars['Float']
  endCapacity: Scalars['Float']
  no: Scalars['Int']
  startCapacity: Scalars['Float']
}

export type ChildData = {
  ID?: Maybe<Scalars['String']>
  active?: Maybe<Scalars['Boolean']>
  createdAt?: Maybe<Scalars['String']>
  createdBy?: Maybe<Scalars['String']>
  enabled?: Maybe<Scalars['Boolean']>
  label?: Maybe<Scalars['String']>
  modifiedAt?: Maybe<Scalars['String']>
  modifiedBy?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  parent?: Maybe<Scalars['String']>
  parentStatus?: Maybe<Scalars['String']>
  statusColor?: Maybe<Scalars['String']>
  statusType?: Maybe<Scalars['String']>
}

export type CircleGeoJsonInput = {
  geometry: CircleGeometryInput
  properties?: InputMaybe<GeoPropertiesInput>
  type: Scalars['String']
}

export type CircleGeometry = {
  circleCoordinates: Array<Scalars['Float']>
  type: Scalars['String']
}

export type CircleGeometryInput = {
  coordinates: Array<Scalars['Float']>
  type: GeofenceType
}

export type CommadRecordData = {
  ID: Scalars['String']
  commandRequest: Scalars['String']
  createdAt: Scalars['String']
  imei: Scalars['String']
  sourceRequest: CommandSource
  statusRequest: Scalars['Boolean']
  vinNo: Scalars['String']
}

export type CommandListParam = {
  endDate?: InputMaybe<Scalars['String']>
  pagination?: InputMaybe<PaginationParam>
  search?: InputMaybe<Scalars['String']>
  sortOrder?: InputMaybe<SortOrder>
  startDate?: InputMaybe<Scalars['String']>
}

export type CommandListResponse = {
  data: Array<CommadRecordData>
  lastUpdate: Scalars['String']
  pagination?: Maybe<PaginationResult>
}

export type CommandParam = {
  Id: Scalars['String']
  RequireResponse?: InputMaybe<Scalars['Boolean']>
  Type: Scalars['String']
}

export type CommandReply = {
  response: Scalars['String']
  success: Scalars['Boolean']
}

export type CommandResponse = {
  Id: Scalars['String']
  Type: Scalars['String']
  commandReply: CommandReply
}

export type CommandSource = {
  appID: Scalars['String']
  deviceID: Scalars['String']
  location: Scalars['String']
  type: Scalars['String']
  user: Scalars['String']
}

export type CommandsMutation = {
  sendCommand: CommandResponse
}

export type CommandsMutationSendCommandArgs = {
  param: CommandParam
}

export type ContactParam = {
  content: Scalars['String']
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  phone: Scalars['String']
}

export type Coordinate = {
  latitude: Scalars['String']
  longitude: Scalars['String']
}

export type CreateLocationParam = {
  code: Scalars['String']
  coordinate: SiteCoordinate
  enabled: Scalars['Boolean']
  loadingRadius?: InputMaybe<Scalars['Int']>
  materialID?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  parent?: InputMaybe<Scalars['String']>
  queueRadius?: InputMaybe<Scalars['Int']>
  tenantID: Scalars['String']
  timezoneID: Scalars['String']
}

export type CreateReprocessCycleRequestParam = {
  endTime: Scalars['String']
  siteId: Scalars['String']
  startTime: Scalars['String']
  type: ReprocessCycleType
}

export type CrewData = {
  driverList?: Maybe<Array<Maybe<DriverList>>>
  driverShift?: Maybe<Scalars['String']>
  shift?: Maybe<Scalars['String']>
  shiftEnd?: Maybe<Scalars['String']>
  shiftStart?: Maybe<Scalars['String']>
  workShiftID?: Maybe<Scalars['String']>
}

export type CrewManagementList = {
  CrewData?: Maybe<Array<Maybe<CrewData>>>
  FleetName?: Maybe<Scalars['String']>
  FleetType?: Maybe<Scalars['String']>
  ID?: Maybe<Scalars['String']>
}

export type CrewManagementListParam = {
  filterShift: Scalars['String']
  filterUnit?: InputMaybe<Array<Scalars['String']>>
  locationID?: InputMaybe<Scalars['String']>
  pagination?: InputMaybe<PaginationParam>
  startTime?: InputMaybe<Scalars['String']>
}

export type CrewManagementListResponse = {
  data?: Maybe<Array<Maybe<CrewManagementList>>>
  lastUpdated?: Maybe<Scalars['String']>
  pagination?: Maybe<PaginationResult>
}

export type CrudResponse = {
  id: Scalars['String']
  message: Scalars['String']
  success: Scalars['Boolean']
}

export type CrudResponseWithImage = {
  ID: Scalars['String']
  Message: Scalars['String']
  Name: Scalars['String']
  Success: Scalars['Boolean']
}

export type CycleEventDetailResponse = {
  ID: Scalars['String']
  delayTipeDescription: Scalars['String']
  delayTipeDescriptionID: Scalars['String']
  delayType: Scalars['String']
  delayTypeID: Scalars['String']
  driverId: Scalars['String']
  driverName: Scalars['String']
  endTime: Scalars['String']
  fleetId: Scalars['String']
  remarks: Scalars['String']
  startTime: Scalars['String']
  status: Scalars['String']
  unitName: Scalars['String']
  workingStatusId: Scalars['String']
}

export type CycleEventListData = {
  assetID?: Maybe<Scalars['String']>
  assetName?: Maybe<Scalars['String']>
  createdOn?: Maybe<Scalars['String']>
  enabled?: Maybe<Scalars['Boolean']>
  endTime?: Maybe<Scalars['String']>
  eventDescription?: Maybe<Scalars['String']>
  eventDescriptionID?: Maybe<Scalars['String']>
  eventID?: Maybe<Scalars['String']>
  eventType?: Maybe<Scalars['String']>
  modifiedBy?: Maybe<Scalars['String']>
  modifiedOn?: Maybe<Scalars['String']>
  remarks?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['String']>
  unitType?: Maybe<Scalars['String']>
}

export type CycleEventListParam = {
  assetID?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  eventDescriptionID?: InputMaybe<Scalars['String']>
  isOngoing?: InputMaybe<Scalars['Boolean']>
  isSystem?: InputMaybe<Scalars['Boolean']>
  pagination?: InputMaybe<PaginationParam>
  siteID?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<CycleEventListSortBy>
  sortOrder?: InputMaybe<SortOrder>
  startDate?: InputMaybe<Scalars['String']>
}

export type CycleEventListResponse = {
  data: Array<CycleEventListData>
  lastUpdate?: Maybe<Scalars['String']>
  pagination?: Maybe<PaginationResult>
}

export enum CycleEventListSortBy {
  SortCreatedAt = 'SORT_CREATED_AT',
  SortEndTime = 'SORT_END_TIME',
  SortStartTime = 'SORT_START_TIME',
  SortUpdatedAt = 'SORT_UPDATED_AT',
}

export type CycleEventParam = {
  driverId?: InputMaybe<Scalars['String']>
  endTime?: InputMaybe<Scalars['String']>
  fleetId: Scalars['String']
  locationID: Scalars['String']
  offsetTime: AllOffsetsTimeZone
  remarks: Scalars['String']
  startTime: Scalars['String']
  workingStatusId: Scalars['String']
}

export type DashboardStatisticResponse = {
  avgSpeed: Scalars['String']
  distance: Scalars['String']
  duration: Scalars['String']
  topSpeed: Scalars['String']
}

export type DateRangeSubscription = {
  from: Scalars['String']
  to: Scalars['String']
  type: SubscriptionsRangeType
}

export type DetailAreaResponse = {
  data?: Maybe<Location>
}

export type DeviceData = {
  ID?: Maybe<Scalars['String']>
  active?: Maybe<Scalars['Boolean']>
  deviceModelID?: Maybe<Scalars['String']>
  imei?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  tenantID?: Maybe<Scalars['String']>
}

export type DeviceDataResponse = {
  data?: Maybe<Array<Maybe<DeviceData>>>
}

export type DeviceDetailsParam = {
  ID: Scalars['String']
  disableUserOrgFilter?: InputMaybe<Scalars['Boolean']>
  intervalDaysTelemetry?: InputMaybe<Scalars['Int']>
  periodTelemetry?: InputMaybe<PeriodTelemetry>
  searchBy?: InputMaybe<DevicesSearchType>
  telemetryCount?: InputMaybe<Scalars['Int']>
  telemetrySortOrder?: InputMaybe<SortOrder>
  trimmedTelemetry?: InputMaybe<Scalars['Boolean']>
}

export type DeviceModelsData = {
  ID: Scalars['String']
  active: Scalars['Boolean']
  brand: Scalars['String']
  model: Scalars['String']
  protocol: Scalars['String']
}

export type DeviceModelsListResponse = {
  data: Array<DeviceModelsData>
  pagination?: Maybe<PaginationResult>
}

export type Devices = {
  detailDevices: DevicesDetailResponse
  detailMiningDevice?: Maybe<MiningDevicesDetailResponse>
  listCommandRecords: CommandListResponse
  listDeviceModel: DeviceModelsListResponse
  listDevices: DevicesListResponse
  listMiningDevices?: Maybe<MiningDevicesListResponse>
  listSubscriptions: SubscriptionsListResponse
  listTestRecords: TestListResponse
  subscriptionHistory?: Maybe<SubscriptionHistoryResponse>
}

export type DevicesDetailDevicesArgs = {
  param?: InputMaybe<DeviceDetailsParam>
}

export type DevicesDetailMiningDeviceArgs = {
  id: Scalars['String']
}

export type DevicesListCommandRecordsArgs = {
  param?: InputMaybe<CommandListParam>
}

export type DevicesListDeviceModelArgs = {
  param?: InputMaybe<ListParam>
}

export type DevicesListDevicesArgs = {
  param?: InputMaybe<DevicesListParam>
}

export type DevicesListMiningDevicesArgs = {
  param?: InputMaybe<MiningDeviceListParam>
}

export type DevicesListSubscriptionsArgs = {
  param?: InputMaybe<SubscriptionsListParam>
}

export type DevicesListTestRecordsArgs = {
  param?: InputMaybe<TestListParam>
}

export type DevicesSubscriptionHistoryArgs = {
  id: Scalars['String']
}

export type DevicesData = {
  ID: Scalars['String']
  active: Scalars['Boolean']
  battery: Scalars['Float']
  billingStatus: Scalars['String']
  bluetoothName: Scalars['String']
  endQcDate: Scalars['String']
  fleetID: Scalars['String']
  imei: Scalars['String']
  manufacture?: Maybe<ManufactureData>
  model: Scalars['String']
  properties?: Maybe<Array<Properties>>
  sim: Scalars['String']
  startQcDate: Scalars['String']
  status: Scalars['String']
  tags?: Maybe<Array<Properties>>
  updatedAt: Scalars['String']
  vinNO: Scalars['String']
}

export type DevicesDeleteResponse = {
  ID: Scalars['String']
}

export type DevicesDetailResponse = {
  device?: Maybe<DevicesData>
  position?: Maybe<Array<Maybe<PositionData>>>
  vehicle?: Maybe<VehicleData>
}

export enum DevicesFilterType {
  Assigned = 'ASSIGNED',
  None = 'NONE',
  Unassigned = 'UNASSIGNED',
}

export type DevicesListParam = {
  QCFilter?: InputMaybe<QcFilterType>
  filterBy?: InputMaybe<DevicesFilterType>
  manufactureQCFilter?: InputMaybe<QcFilterType>
  pagination?: InputMaybe<PaginationParam>
  search?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<SortBy>
  sortOrder?: InputMaybe<SortOrder>
}

export type DevicesListResponse = {
  data: Array<DevicesData>
  lastUpdate: Scalars['String']
  pagination?: Maybe<PaginationResult>
}

export type DevicesMutation = {
  assignDevices: AssignDevicesResponse
  createDevice: CrudResponse
  createMiningDevice: CrudResponse
  createTestRecord: CrudResponse
  deleteDevices: CrudResponse
  deleteMiningDevice: CrudResponse
  editSimStatus: CrudResponse
  iccidUpload: File
  singleUpload: File
  updateDevice: CrudResponse
  updateManufacture: CrudResponse
  updateMiningDevice: CrudResponse
  updateSubscription: CrudResponse
}

export type DevicesMutationAssignDevicesArgs = {
  param?: InputMaybe<AssignDevicesParam>
}

export type DevicesMutationCreateDeviceArgs = {
  param: DevicesParam
}

export type DevicesMutationCreateMiningDeviceArgs = {
  param: MiningDeviceParam
}

export type DevicesMutationCreateTestRecordArgs = {
  param: TestRecordParam
}

export type DevicesMutationDeleteDevicesArgs = {
  id: Scalars['String']
}

export type DevicesMutationDeleteMiningDeviceArgs = {
  id: Scalars['String']
}

export type DevicesMutationEditSimStatusArgs = {
  param?: InputMaybe<TerminalParam>
}

export type DevicesMutationIccidUploadArgs = {
  in: Scalars['Upload']
}

export type DevicesMutationSingleUploadArgs = {
  in: Scalars['Upload']
}

export type DevicesMutationUpdateDeviceArgs = {
  id: Scalars['String']
  param: DevicesParam
}

export type DevicesMutationUpdateManufactureArgs = {
  param: UpdateManufactureParam
}

export type DevicesMutationUpdateMiningDeviceArgs = {
  id: Scalars['String']
  param: MiningDeviceParam
}

export type DevicesMutationUpdateSubscriptionArgs = {
  id: Scalars['String']
  param: SubscriptionsParam
}

export type DevicesParam = {
  bluetoothName: Scalars['String']
  imei?: InputMaybe<Scalars['String']>
  model?: InputMaybe<Scalars['String']>
  sim: Scalars['String']
}

export type DriverAssigmentData = {
  ID: Scalars['String']
  Name: Scalars['String']
}

export type DriverAssigmentParam = {
  driverID: Scalars['String']
  endTime: Scalars['String']
  fleetID: Scalars['String']
  startTime: Scalars['String']
}

export type DriverAssigmnetListResponse = {
  data?: Maybe<Array<Maybe<DriverAssigmentData>>>
}

export type DriverAssignmentCopyParam = {
  date: Scalars['String']
  shiftID: Scalars['String']
  siteID: Scalars['String']
}

export type DriverAssignmentDuplicateRoster = {
  duplicateArrayDate: Array<DuplicateArrayDate>
  locationID: Scalars['String']
  shiftID: Scalars['String']
}

export type DriverData = {
  DriverLicenseData?: Maybe<Array<Maybe<DriverLicenseData>>>
  ID: Scalars['String']
  active: Scalars['Boolean']
  code: Scalars['String']
  createdAt?: Maybe<Scalars['String']>
  createdBy?: Maybe<Scalars['String']>
  driverNo: Scalars['String']
  enabled: Scalars['Boolean']
  fleetID: Scalars['String']
  fleetName: Scalars['String']
  locationID?: Maybe<Scalars['String']>
  modifiedAt?: Maybe<Scalars['String']>
  modifiedBy?: Maybe<Scalars['String']>
  name: Scalars['String']
  needChangePin: Scalars['Boolean']
  rfid: Scalars['String']
  tenantID: Scalars['String']
}

export type DriverDetailResponse = {
  data: DriverData
}

export type DriverFleetAssigmentData = {
  ID: Scalars['String']
  active: Scalars['Boolean']
  driverID: Scalars['String']
  driverName: Scalars['String']
  endTime: Scalars['String']
  startTime: Scalars['String']
  type: Scalars['String']
}

export type DriverLicenseData = {
  fleetType: Scalars['String']
  licenseFrom: Scalars['String']
  licenseNo: Scalars['String']
  licenseTo: Scalars['String']
}

export enum DriverLicenseFilterType {
  Dumptruck = 'DUMPTRUCK',
  Excavator = 'EXCAVATOR',
  None = 'NONE',
}

export type DriverList = {
  driverAssignmentID?: Maybe<Scalars['String']>
  driverID?: Maybe<Scalars['String']>
  driverName?: Maybe<Scalars['String']>
  end?: Maybe<Scalars['String']>
  start?: Maybe<Scalars['String']>
}

export type DriverListParam = {
  locationID: Scalars['String']
  pagination?: InputMaybe<PaginationParam>
  search?: InputMaybe<Scalars['String']>
}

export type DriverListResponse = {
  data: Array<DriverData>
  lastUpdated?: Maybe<Scalars['String']>
  pagination?: Maybe<PaginationResult>
}

export type DriverParam = {
  DriverParamLicense?: InputMaybe<Array<InputMaybe<DriverParamLicense>>>
  code?: InputMaybe<Scalars['String']>
  driverNo?: InputMaybe<Scalars['String']>
  enabled: Scalars['Boolean']
  location_id?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  pin?: InputMaybe<Scalars['String']>
  rfid?: InputMaybe<Scalars['String']>
}

export type DriverParamLicense = {
  licenseFrom?: InputMaybe<Scalars['String']>
  licenseNo?: InputMaybe<Scalars['String']>
  licenseTo?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
}

export type DriverUnAssignParam = {
  endTime?: InputMaybe<Scalars['String']>
  filterLicenseDriver?: InputMaybe<DriverLicenseFilterType>
  search?: InputMaybe<Scalars['String']>
  siteID?: InputMaybe<Scalars['String']>
  startTime?: InputMaybe<Scalars['String']>
}

export type Drivers = {
  ListDriverAssign: DriverAssigmnetListResponse
  ListDriverUnassign: DriverAssigmnetListResponse
  OperatorCheckShift: ShiftResponse
  OperatorPerformance: PerformanceResponse
  OperatorShiftHistory: ShiftHistoryResponse
  getAllCrewManagement: CrewManagementListResponse
  getAllDriver: DriverListResponse
  getDetailDriver?: Maybe<DriverDetailResponse>
}

export type DriversListDriverAssignArgs = {
  search?: InputMaybe<Scalars['String']>
}

export type DriversListDriverUnassignArgs = {
  param?: InputMaybe<DriverUnAssignParam>
}

export type DriversOperatorPerformanceArgs = {
  param?: InputMaybe<PerformanceParam>
}

export type DriversOperatorShiftHistoryArgs = {
  param: ShiftHistoryParam
}

export type DriversGetAllCrewManagementArgs = {
  param?: InputMaybe<CrewManagementListParam>
}

export type DriversGetAllDriverArgs = {
  param?: InputMaybe<DriverListParam>
}

export type DriversGetDetailDriverArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type DriversMutation = {
  AssignDriverFleeto: CrudResponse
  CreateDriverAssignment: CrudResponse
  DeleteCrewAssigment: CrudResponse
  DeleteCrewRows: CrudResponse
  DeleteDriverAssignment: CrudResponse
  DeleteDriverFleeto: CrudResponse
  OperatorEndShift: CrudResponse
  OperatorLogin: TokenResponse
  OperatorStartShift: CrudResponse
  OperatorUpdatePin: CrudResponse
  UpdateDriverFleeto: CrudResponse
  copyFromLastDay: CrudResponse
  createDriver: CrudResponse
  createNewAssignment: CrudResponse
  deleteDriver: CrudResponse
  duplicateRoster: CrudResponse
  updateDriver: CrudResponse
}

export type DriversMutationAssignDriverFleetoArgs = {
  driverId: Scalars['String']
  fleetId: Scalars['String']
}

export type DriversMutationCreateDriverAssignmentArgs = {
  param: DriverAssigmentParam
}

export type DriversMutationDeleteCrewAssigmentArgs = {
  id: Scalars['String']
}

export type DriversMutationDeleteCrewRowsArgs = {
  param?: InputMaybe<DriverAssignmentCopyParam>
}

export type DriversMutationDeleteDriverAssignmentArgs = {
  id: Scalars['String']
}

export type DriversMutationDeleteDriverFleetoArgs = {
  driverId: Scalars['String']
  fleetId: Scalars['String']
}

export type DriversMutationOperatorEndShiftArgs = {
  fleetId: Scalars['String']
  timestamp?: InputMaybe<Scalars['String']>
}

export type DriversMutationOperatorLoginArgs = {
  code: Scalars['String']
  pin: Scalars['String']
  site?: InputMaybe<Scalars['String']>
  tenant?: InputMaybe<Scalars['String']>
}

export type DriversMutationOperatorStartShiftArgs = {
  fleetId: Scalars['String']
  timestamp?: InputMaybe<Scalars['String']>
}

export type DriversMutationOperatorUpdatePinArgs = {
  id: Scalars['String']
  pin: Scalars['String']
}

export type DriversMutationUpdateDriverFleetoArgs = {
  driverId: Scalars['String']
  enabled: Scalars['Boolean']
  fleetId: Scalars['String']
}

export type DriversMutationCopyFromLastDayArgs = {
  param?: InputMaybe<DriverAssignmentCopyParam>
}

export type DriversMutationCreateDriverArgs = {
  param?: InputMaybe<DriverParam>
}

export type DriversMutationCreateNewAssignmentArgs = {
  param?: InputMaybe<DriverAssigmentParam>
}

export type DriversMutationDeleteDriverArgs = {
  driverID?: InputMaybe<Scalars['String']>
}

export type DriversMutationDuplicateRosterArgs = {
  param?: InputMaybe<DriverAssignmentDuplicateRoster>
}

export type DriversMutationUpdateDriverArgs = {
  driverID?: InputMaybe<Scalars['String']>
  param?: InputMaybe<DriverParam>
}

export type DrivingEventData = {
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  speed: Scalars['Float']
  time: Scalars['String']
  value: Scalars['String']
}

export type DrivingScoreResponse = {
  count: Scalars['Float']
  crashDetection: Array<DrivingEventData>
  crashDetectioncount: Scalars['Float']
  harshBreaking: Array<DrivingEventData>
  harshBreakingcount: Scalars['Float']
  mean: Scalars['Float']
  median: Scalars['Float']
  overSpeed: Array<DrivingEventData>
  overSpeedcount: Scalars['Float']
  score: Scalars['Float']
}

export type DuplicateArrayDate = {
  date?: InputMaybe<Scalars['String']>
}

export type DurationData = {
  duration: Scalars['String']
  year: Scalars['Int']
}

export type EventCycleData = {
  dumpingEndTime?: Maybe<Scalars['String']>
  dumpingStartTime?: Maybe<Scalars['String']>
  endTime?: Maybe<Scalars['String']>
  eventID?: Maybe<Scalars['String']>
  loadingEndTime?: Maybe<Scalars['String']>
  loadingStartTime?: Maybe<Scalars['String']>
  queueingStartTime?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['String']>
  typeStatus?: Maybe<Scalars['String']>
}

export type EventCycleTimes = {
  eventCycleData?: Maybe<Array<Maybe<EventCycleData>>>
  time: Scalars['String']
}

export type EventParam = {
  endDate?: InputMaybe<Scalars['String']>
  filterArea?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  filterDriver?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  filterUnit?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  filterUnitType?: InputMaybe<FilterUnitAllCrew>
  filterWorkshift?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  isOngoing?: InputMaybe<Scalars['Boolean']>
  isSystem?: InputMaybe<Scalars['Boolean']>
  locationID: Scalars['String']
  offsetTime: AllOffsetsTimeZone
  pagination?: InputMaybe<PaginationParam>
  startDate?: InputMaybe<Scalars['String']>
}

export type ExcessiveIdlingResponse = {
  data: Array<IdlingData>
  total: Scalars['Float']
}

export type Field = {
  name: Scalars['String']
  value: Scalars['String']
}

export type File = {
  message: Scalars['String']
  name: Scalars['String']
  success: Scalars['Boolean']
}

export enum FilterAssetType {
  DumpTruck = 'DUMP_TRUCK',
  Excavator = 'EXCAVATOR',
}

export enum FilterAssignment {
  Area = 'AREA',
  Asset = 'ASSET',
}

export enum FilterUnitAllCrew {
  GetDumpTruck = 'GET_DUMP_TRUCK',
  GetExcavator = 'GET_EXCAVATOR',
}

export type FleetCycleSummary = {
  ID: Scalars['String']
  batteryUsageKwh?: Maybe<Scalars['Float']>
  departureTime: Scalars['String']
  firstRouteName: Scalars['String']
  firstStationArrivalTime: Scalars['String']
  firstStationGeofenceID: Scalars['String']
  firstStationMileageKm: Scalars['Float']
  fleetID: Scalars['String']
  lastStationMileageKm: Scalars['Float']
  poolGeofenceID: Scalars['String']
  returnArrivalTime: Scalars['String']
  returnBatterySocPct?: Maybe<Scalars['Float']>
  returnDepartureTime: Scalars['String']
  returnFromGeofenceID: Scalars['String']
  returnFromRouteName: Scalars['String']
  returnMileageKm: Scalars['Float']
  startBatterySocPct?: Maybe<Scalars['Float']>
  startMileageKm: Scalars['Float']
  trip1MileageKm?: Maybe<Scalars['Float']>
  trip2MileageKm?: Maybe<Scalars['Float']>
  trip11MileageKm?: Maybe<Scalars['Float']>
  trip12MileageKm?: Maybe<Scalars['Float']>
  trip21MileageKm?: Maybe<Scalars['Float']>
  trip22MileageKm?: Maybe<Scalars['Float']>
}

export type GeoJson = {
  ID: Scalars['String']
  detect: Array<Scalars['String']>
  geometry: Geometry
  name: Scalars['String']
  orgID: Scalars['String']
  properties?: Maybe<Array<Maybe<Properties>>>
  type: Scalars['String']
}

export type GeoJsonResponse = {
  data: Array<GeoJson>
  pagination?: Maybe<PaginationResult>
}

export type GeoProperties = {
  radius?: Maybe<Scalars['String']>
}

export type GeoPropertiesInput = {
  radius?: InputMaybe<Scalars['String']>
}

export type GeofenceEventsData = {
  ID: Scalars['String']
  coordinate: Array<Scalars['Float']>
  event: Scalars['String']
  fleetId: Scalars['String']
  fleetName: Scalars['String']
  geofenceId: Scalars['String']
  geofenceName: Scalars['String']
  orgId: Scalars['String']
  time: Scalars['String']
}

export type GeofenceEventsResponse = {
  data: Array<GeofenceEventsData>
  pagination?: Maybe<PaginationResult>
}

export enum GeofenceType {
  LineString = 'LineString',
  Point = 'Point',
  Polygon = 'Polygon',
}

export type Geofences = {
  ListWebhookUrl: WebhookUrlResponse
  detailGeofences: GeoJson
  fetchAssignedGeofences: GeoJsonResponse
  fetchGeofenceEvents: GeofenceEventsResponse
  fetchNotificationsEvents: GeofenceEventsResponse
  fetchTHRgeofenceEvents: Array<ThrGeofenceEventsResponse>
  listGeoLocations: GeolocationResponse
  listGeofences: GeoJsonResponse
}

export type GeofencesListWebhookUrlArgs = {
  orgID?: InputMaybe<Scalars['String']>
  param?: InputMaybe<ListParam>
}

export type GeofencesDetailGeofencesArgs = {
  id: Scalars['String']
}

export type GeofencesFetchAssignedGeofencesArgs = {
  param: GeofencesParam
}

export type GeofencesFetchGeofenceEventsArgs = {
  endDate?: InputMaybe<Scalars['String']>
  fleetid?: InputMaybe<Scalars['String']>
  isDashboard?: InputMaybe<Scalars['Boolean']>
  param?: InputMaybe<ListParam>
  search?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Array<RouteType>>
}

export type GeofencesFetchNotificationsEventsArgs = {
  endDate?: InputMaybe<Scalars['String']>
  fleetid?: InputMaybe<Scalars['String']>
  isDashboard?: InputMaybe<Scalars['Boolean']>
  param?: InputMaybe<ListParam>
  search?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Array<RouteType>>
}

export type GeofencesFetchThRgeofenceEventsArgs = {
  param?: InputMaybe<HistoryParam>
}

export type GeofencesListGeoLocationsArgs = {
  param?: InputMaybe<ListParam>
}

export type GeofencesListGeofencesArgs = {
  param?: InputMaybe<ListParam>
}

export type GeofencesMutation = {
  AssignGeofences: CrudResponse
  CreateCircle: CrudResponse
  CreateGeoLocation: CrudResponse
  CreateLineString: CrudResponse
  CreatePolygon: CrudResponse
  CreateWebhookURL: CrudResponse
  DeleteGeoLocation: CrudResponse
  DeleteGeofences: CrudResponse
  DeleteWebhookURL: CrudResponse
  EditGeoLocation: CrudResponse
  EditWebhookURL: CrudResponse
  UnassignGeofences: CrudResponse
  UpdateCircle: CrudResponse
  UpdateLineString: CrudResponse
  UpdatePolygon: CrudResponse
}

export type GeofencesMutationAssignGeofencesArgs = {
  param: AssignmentInput
}

export type GeofencesMutationCreateCircleArgs = {
  data: CircleGeoJsonInput
  detect: Array<Scalars['String']>
  name: Scalars['String']
}

export type GeofencesMutationCreateGeoLocationArgs = {
  data: GeolocationInput
}

export type GeofencesMutationCreateLineStringArgs = {
  data: LineStringGeoJsonInput
  detect: Array<Scalars['String']>
  name: Scalars['String']
}

export type GeofencesMutationCreatePolygonArgs = {
  data: PolygonGeoJsonInput
  detect: Array<Scalars['String']>
  name: Scalars['String']
}

export type GeofencesMutationCreateWebhookUrlArgs = {
  data: WebhookUrlInput
  orgID?: InputMaybe<Scalars['String']>
}

export type GeofencesMutationDeleteGeoLocationArgs = {
  id: Scalars['String']
}

export type GeofencesMutationDeleteGeofencesArgs = {
  id: Scalars['String']
}

export type GeofencesMutationDeleteWebhookUrlArgs = {
  id: Scalars['String']
}

export type GeofencesMutationEditGeoLocationArgs = {
  data: GeolocationInput
  id: Scalars['String']
}

export type GeofencesMutationEditWebhookUrlArgs = {
  data: WebhookUrlInput
  id: Scalars['String']
}

export type GeofencesMutationUnassignGeofencesArgs = {
  param: AssignmentInput
}

export type GeofencesMutationUpdateCircleArgs = {
  data: CircleGeoJsonInput
  detect: Array<Scalars['String']>
  id: Scalars['String']
  name: Scalars['String']
}

export type GeofencesMutationUpdateLineStringArgs = {
  data: LineStringGeoJsonInput
  detect: Array<Scalars['String']>
  id: Scalars['String']
  name: Scalars['String']
}

export type GeofencesMutationUpdatePolygonArgs = {
  data: PolygonGeoJsonInput
  detect: Array<Scalars['String']>
  id: Scalars['String']
  name: Scalars['String']
}

export type GeofencesParam = {
  ListParam?: InputMaybe<ListParam>
  orgID: Scalars['String']
}

export type GeolocationData = {
  ID: Scalars['String']
  description: Scalars['String']
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  name: Scalars['String']
  type: Scalars['String']
}

export type GeolocationInput = {
  description: Scalars['String']
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  name: Scalars['String']
  type: Scalars['String']
}

export type GeolocationResponse = {
  data: Array<GeolocationData>
  pagination?: Maybe<PaginationResult>
}

export type Geometry = CircleGeometry | LineStringGeometry | PolygonGeometry

export type GetAllAreaParam = {
  pagination?: InputMaybe<PaginationParam>
  parentID: Scalars['String']
  search?: InputMaybe<Scalars['String']>
  sortByArea?: InputMaybe<SortByArea>
  sortOrder?: InputMaybe<SortOrder>
}

export type HeatmapData = {
  data: Array<HeatmapDetails>
  fleetID: Scalars['String']
}

export type HeatmapDetails = {
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  time: Scalars['String']
}

export type HeatmapParam = {
  endTime: Scalars['String']
  interval: Scalars['Int']
  siteID: Scalars['String']
  startTime: Scalars['String']
}

export type HeatmapResponse = {
  data: Array<HeatmapData>
}

export type HistoryParam = {
  ID: Scalars['String']
  endDate: Scalars['String']
  startDate: Scalars['String']
}

export type HistoryTimeParam = {
  ID: Scalars['String']
  endTime: Scalars['String']
  startTime: Scalars['String']
}

export type HoursRow = {
  hourEnd?: Maybe<Scalars['Int']>
  hourStart?: Maybe<Scalars['Int']>
  minuteEnd?: Maybe<Scalars['Int']>
  minuteStart?: Maybe<Scalars['Int']>
  shiftName?: Maybe<Scalars['String']>
}

export type IdlingData = {
  duration: Scalars['Float']
  endTime: Scalars['String']
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  startTime: Scalars['String']
}

export type InputData = {
  Data: Array<Position>
  ID: Scalars['String']
}

export type LastPositionData = {
  ID: Scalars['String']
  data?: Maybe<VehicleLastPositionData>
}

export enum LastPositionFilterType {
  Idle = 'IDLE',
  Moving = 'MOVING',
  None = 'NONE',
  NotAvailable = 'NOT_AVAILABLE',
  Stop = 'STOP',
}

export type LastPositionListParam = {
  disableUserOrgFilter?: InputMaybe<Scalars['Boolean']>
  filterBy?: InputMaybe<LastPositionFilterType>
  filterByAssignment?: InputMaybe<VehicleFilterType>
  orgID?: InputMaybe<Scalars['String']>
  pagination?: InputMaybe<PaginationParam>
  search?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<SortByVehicles>
  sortOrder?: InputMaybe<SortOrder>
}

export type LastPositionListTrimmedParam = {
  deviceModelID: Scalars['String']
}

export type LastPositionResponse = {
  data: Array<LastPositionData>
  pagination?: Maybe<PaginationResult>
}

export type LastPositionTrimmedResponse = {
  positionData: Array<PositionDataTrimmed>
}

export type LatencyData = {
  fleetID: Scalars['String']
  fleetName: Scalars['String']
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  serverTime: Scalars['String']
  time: Scalars['String']
}

export type LatencyParam = {
  endTime: Scalars['String']
  interval: Scalars['Int']
  limit: Scalars['Int']
  siteID: Scalars['String']
  startTime: Scalars['String']
}

export type LineStringGeoJsonInput = {
  geometry: LineStringGeometryInput
  properties?: InputMaybe<GeoPropertiesInput>
  type: Scalars['String']
}

export type LineStringGeometry = {
  lineStringCoordinates: Array<Array<Scalars['Float']>>
  type: Scalars['String']
}

export type LineStringGeometryInput = {
  coordinates: Array<Array<Scalars['Float']>>
  type: GeofenceType
}

export type ListParam = {
  pagination?: InputMaybe<PaginationParam>
  search?: InputMaybe<Scalars['String']>
}

export type LiveDispatchParam = {
  date: Scalars['String']
  filterShift?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type LoadFactorData = {
  ID?: Maybe<Scalars['String']>
  assetManufacture?: Maybe<Scalars['String']>
  capacity?: Maybe<Scalars['Float']>
  createdBy?: Maybe<Scalars['String']>
  createdOn?: Maybe<Scalars['String']>
  enabled?: Maybe<Scalars['Boolean']>
  fleetModelID?: Maybe<Scalars['String']>
  loadFactor?: Maybe<Scalars['Float']>
  material?: Maybe<Scalars['String']>
  tenantID?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
}

export type LoadFactorListParam = {
  locationID?: InputMaybe<Scalars['String']>
  pagination: PaginationParam
  search?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<SortByLoadFactor>
  sortOrder?: InputMaybe<SortOrder>
}

export type LoadFactorListResponse = {
  data?: Maybe<Array<Maybe<LoadFactorData>>>
  lastUpdated?: Maybe<Scalars['String']>
  pagination?: Maybe<PaginationResult>
}

export type LoadFactorParams = {
  capacity?: InputMaybe<Scalars['Float']>
  enabled?: InputMaybe<Scalars['Boolean']>
  fleetModelID?: InputMaybe<Scalars['String']>
  loadFactor?: InputMaybe<Scalars['Float']>
  materialID?: InputMaybe<Scalars['String']>
}

export type Location = {
  active?: Maybe<Scalars['Boolean']>
  area?: Maybe<Geometry>
  code?: Maybe<Scalars['String']>
  companyID?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  createdBy?: Maybe<Scalars['String']>
  enabled?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['String']>
  imageOverlayUrl?: Maybe<Scalars['String']>
  materialID?: Maybe<Scalars['String']>
  materialName?: Maybe<Scalars['String']>
  modifiedAt?: Maybe<Scalars['String']>
  modifiedBy?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  offset?: Maybe<Scalars['Int']>
  parent?: Maybe<Scalars['String']>
  properties?: Maybe<Array<Maybe<Properties>>>
  region?: Maybe<Scalars['String']>
  subAreaType?: Maybe<Scalars['String']>
  timezone?: Maybe<Scalars['String']>
  timezoneID?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type LocationList = {
  siteID: Scalars['String']
}

export type LocationListResponse = {
  data: Array<Location>
  lastUpdated?: Maybe<Scalars['String']>
  pagination?: Maybe<PaginationResult>
}

export type LocationParam = {
  active: Scalars['Boolean']
  code: Scalars['String']
  coordinate: SiteCoordinate
  enabled: Scalars['Boolean']
  loadingRadius?: InputMaybe<Scalars['Int']>
  materialID?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  parent?: InputMaybe<Scalars['String']>
  queueRadius?: InputMaybe<Scalars['Int']>
  tenantID?: InputMaybe<Scalars['String']>
  timeZoneID: Scalars['String']
}

export type LocationSiteListParam = {
  areaID?: InputMaybe<Scalars['String']>
  filterCreatedBy?: InputMaybe<Scalars['String']>
  filterCreatedOn?: InputMaybe<Scalars['String']>
  filterLocationName?: InputMaybe<Scalars['String']>
  groupID?: InputMaybe<Scalars['String']>
  pagination?: InputMaybe<PaginationParam>
  search?: InputMaybe<Scalars['String']>
  sortBySite?: InputMaybe<SortBySite>
  sortBySubArea?: InputMaybe<SortBySubArea>
  sortOrder?: InputMaybe<SortOrder>
  tenantID?: InputMaybe<Scalars['String']>
}

export type LocationSubAreaParam = {
  code: Scalars['String']
  coordinate: Array<InputMaybe<SiteCoordinate>>
  enabled: Scalars['Boolean']
  loadingRadius?: InputMaybe<Scalars['Int']>
  materialID?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  parent: Scalars['String']
  queueRadius?: InputMaybe<Scalars['Int']>
  subAreaType: Scalars['String']
}

export type LocationsList = {
  area?: Maybe<Array<Maybe<Location>>>
  subArea?: Maybe<Array<Maybe<Location>>>
}

export type Mails = {
  sendContactEmail: CrudResponse
}

export type MailsSendContactEmailArgs = {
  param: ContactParam
}

export type ManufactureData = {
  date?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
}

export type MaterialAssigmentChild = {
  ID: Scalars['String']
  areaID: Scalars['String']
  areaName: Scalars['String']
  assetClass: Scalars['String']
  assetDescription: Scalars['String']
  assetID: Scalars['String']
  endTimestamp: Scalars['String']
  materialID: Scalars['String']
  materialType: Scalars['String']
  remarks: Scalars['String']
  startTimestamp: Scalars['String']
}

export type MaterialAssigmentListResponse = {
  data: Array<MaterialAssignmentData>
  lastUpdated: Scalars['String']
}

export type MaterialAssignmentData = {
  areaID: Scalars['String']
  areaName: Scalars['String']
  assetID: Scalars['String']
  assetName: Scalars['String']
  items: Array<MaterialAssigmentChild>
}

export enum MaterialAssignmentFilterType {
  Dumptruck = 'DUMPTRUCK',
  Excavator = 'EXCAVATOR',
  None = 'NONE',
}

export type MaterialAssignmentListParam = {
  endDate: Scalars['String']
  filterBy?: InputMaybe<MaterialAssignmentFilterType>
  locationID?: InputMaybe<Scalars['String']>
  sortOrder?: InputMaybe<SortOrder>
  startDate: Scalars['String']
}

export type MaterialData = {
  ID: Scalars['String']
  active: Scalars['Boolean']
  code: Scalars['String']
  color: Scalars['String']
  createdAt: Scalars['String']
  createdBy: Scalars['String']
  modifiedAt: Scalars['String']
  modifiedBy: Scalars['String']
  name: Scalars['String']
  sietID: Scalars['String']
  tenantID: Scalars['String']
  uom: Scalars['String']
}

export type MaterialDetailResponse = {
  data: MaterialData
}

export enum MaterialFilterType {
  Createdat = 'CREATEDAT',
  Createdby = 'CREATEDBY',
  Materialcode = 'MATERIALCODE',
  Materialname = 'MATERIALNAME',
  Uom = 'UOM',
  Updatedat = 'UPDATEDAT',
}

export type MaterialListParam = {
  filterBy?: InputMaybe<MaterialFilterType>
  pagination?: InputMaybe<PaginationParam>
  search?: InputMaybe<Scalars['String']>
  siteID?: InputMaybe<Scalars['String']>
  sortByMasterMaterial?: InputMaybe<SortByMasterMaterial>
  sortOrder?: InputMaybe<SortOrder>
}

export type MaterialListResponse = {
  data: Array<MaterialData>
  lastUpdated?: Maybe<Scalars['String']>
  pagination?: Maybe<PaginationResult>
}

export type MaterialParam = {
  active: Scalars['Boolean']
  code: Scalars['String']
  color: Scalars['String']
  name: Scalars['String']
  siteID: Scalars['String']
  uom: Scalars['String']
}

export type MaterialTabularData = {
  ID?: Maybe<Scalars['String']>
  area?: Maybe<Scalars['String']>
  asset?: Maybe<Scalars['String']>
  creatdOn?: Maybe<Scalars['String']>
  createdBy?: Maybe<Scalars['String']>
  enabled?: Maybe<Scalars['Boolean']>
  endTime?: Maybe<Scalars['String']>
  locationID?: Maybe<Scalars['String']>
  materialType?: Maybe<Scalars['String']>
  remarks?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['String']>
  updateAt?: Maybe<Scalars['String']>
}

export type MaterialTabularViews = {
  data?: Maybe<Array<Maybe<MaterialTabularData>>>
  lastUpdated?: Maybe<Scalars['String']>
  pagination?: Maybe<PaginationResult>
}

export type MaterialTabularViewsParams = {
  FilterArea?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  FilterEnd?: InputMaybe<Scalars['String']>
  FilterStart?: InputMaybe<Scalars['String']>
  FilterUnit?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  active?: InputMaybe<Scalars['Boolean']>
  filterAssignment?: InputMaybe<FilterAssignment>
  pagination?: InputMaybe<PaginationParam>
  search?: InputMaybe<Scalars['String']>
  siteID: Scalars['String']
  sortBy?: InputMaybe<SortByMaterialAssignment>
  sortOrder?: InputMaybe<SortOrder>
}

export type MiningCycle = {
  fetchReprocessCycleRequestList?: Maybe<ReprocessCycleRequestList>
}

export type MiningCycleFetchReprocessCycleRequestListArgs = {
  siteId: Scalars['String']
}

export type MiningCycleMutation = {
  createReprocessCycleRequest: CrudResponse
  deleteReprocessCycleRequest: CrudResponse
}

export type MiningCycleMutationCreateReprocessCycleRequestArgs = {
  param?: InputMaybe<CreateReprocessCycleRequestParam>
}

export type MiningCycleMutationDeleteReprocessCycleRequestArgs = {
  id: Scalars['String']
}

export type MiningCycleReportData = {
  delayDurationSeconds: Scalars['Float']
  dumpingAreaName: Scalars['String']
  dumpingEndTime: Scalars['String']
  dumpingStartTime: Scalars['String']
  endTime: Scalars['String']
  excavatorId: Scalars['String']
  excavatorName: Scalars['String']
  excavatorOperator: Scalars['String']
  loadingAreaName: Scalars['String']
  loadingEndTime: Scalars['String']
  loadingStartTime: Scalars['String']
  material: Scalars['String']
  operatorCodeDumptruck: Scalars['String']
  operatorCodeExcavator: Scalars['String']
  productionDate: Scalars['String']
  queueingStartTime: Scalars['String']
  shift: Scalars['String']
  shiftCode: Scalars['String']
  startTime: Scalars['String']
  travelLadenDistance: Scalars['Int']
  travelLadenMovingSpeed: Scalars['Int']
  travelLadenSpeed: Scalars['Int']
  travelUnladenDistance: Scalars['Int']
  travelUnladenMovingSpeed: Scalars['Int']
  travelUnladenSpeed: Scalars['Int']
  truckId: Scalars['String']
  truckName: Scalars['String']
  truckOperator: Scalars['String']
}

export type MiningCycleReportDataParam = {
  dumpingArea?: InputMaybe<Array<Scalars['String']>>
  endTime?: InputMaybe<Scalars['String']>
  filterDumptruck?: InputMaybe<Array<Scalars['String']>>
  filterExca?: InputMaybe<Array<Scalars['String']>>
  filterShift?: InputMaybe<Array<Scalars['String']>>
  loadingArea?: InputMaybe<Array<Scalars['String']>>
  maxDuration?: InputMaybe<Scalars['Int']>
  minDuration?: InputMaybe<Scalars['Int']>
  pagination?: InputMaybe<PaginationParam>
  search?: InputMaybe<Scalars['String']>
  siteId?: InputMaybe<Scalars['String']>
  sortByCycleReport?: InputMaybe<SortByCycleReport>
  sortOrder?: InputMaybe<SortOrder>
  startTime?: InputMaybe<Scalars['String']>
}

export type MiningCycleReportDataResponse = {
  data?: Maybe<Array<Maybe<MiningCycleReportData>>>
  lastUpdated?: Maybe<Scalars['String']>
  pagination?: Maybe<PaginationResult>
}

export type MiningDeviceData = {
  ID?: Maybe<Scalars['String']>
  active?: Maybe<Scalars['Boolean']>
  createdAt?: Maybe<Scalars['String']>
  createdBy?: Maybe<Scalars['String']>
  enable?: Maybe<Scalars['Boolean']>
  imei?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  sim?: Maybe<Scalars['String']>
  siteID?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  typeID?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  updatedBy?: Maybe<Scalars['String']>
}

export type MiningDeviceListParam = {
  pagination?: InputMaybe<PaginationParam>
  search?: InputMaybe<Scalars['String']>
  siteID?: InputMaybe<Scalars['String']>
  sortByMasterDevices?: InputMaybe<SortByMasterDevices>
  sortOrder?: InputMaybe<SortOrder>
}

export type MiningDeviceParam = {
  imei?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  sim?: InputMaybe<Scalars['String']>
  siteID?: InputMaybe<Scalars['String']>
  typeID?: InputMaybe<Scalars['String']>
}

export type MiningDevicesDetailResponse = {
  miningDeviceData: MiningDeviceData
}

export type MiningDevicesListResponse = {
  lastUpdated?: Maybe<Scalars['String']>
  miningDeviceData: Array<MiningDeviceData>
  pagination?: Maybe<PaginationResult>
}

export type MiningDispacthDataMaintanceResponse = {
  ID?: Maybe<Scalars['String']>
  label?: Maybe<Scalars['String']>
  remarks?: Maybe<Scalars['String']>
  statusName?: Maybe<Scalars['String']>
  time?: Maybe<Scalars['String']>
  unitName?: Maybe<Scalars['String']>
}

export type MiningDispacthDataResponse = {
  data?: Maybe<Array<Maybe<MiningDispacthReportData>>>
  dumpingAvg?: Maybe<Scalars['String']>
  excavatorName?: Maybe<Scalars['String']>
  fillingAvg?: Maybe<Scalars['String']>
  hourly?: Maybe<Scalars['String']>
  loadingAvg?: Maybe<Scalars['String']>
  material?: Maybe<Scalars['String']>
  queuingAvg?: Maybe<Scalars['String']>
  totalLoad?: Maybe<Scalars['Int']>
}

export type MiningDispacthParkingUnitReponse = {
  ID?: Maybe<Scalars['String']>
  label?: Maybe<Scalars['String']>
  remarks?: Maybe<Scalars['String']>
  statusName?: Maybe<Scalars['String']>
  time?: Maybe<Scalars['String']>
  unitName?: Maybe<Scalars['String']>
}

export type MiningDispacthReportData = {
  countLoad?: Maybe<Scalars['String']>
  excavatorId?: Maybe<Scalars['String']>
  excavatorName?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  truckId?: Maybe<Scalars['String']>
  truckName?: Maybe<Scalars['String']>
}

export type MiningFetchFleetsLiveMapItem = {
  ID: Scalars['String']
  breakdownStartTime: Scalars['String']
  driverName: Scalars['String']
  fleetName: Scalars['String']
  fleetType: Scalars['String']
  ignition: Scalars['Boolean']
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  status: Scalars['String']
  statusType: Scalars['String']
  time: Scalars['String']
}

export type MiningFetchFleetsLiveMapParam = {
  filterByAssignment?: InputMaybe<VehicleFilterType>
  filterDriver?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  filterFleet?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  filterUnit?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  search: Scalars['String']
  siteID: Scalars['String']
}

export type MiningFetchFleetsLiveMapResponse = {
  fleets?: Maybe<Array<MiningFetchFleetsLiveMapItem>>
}

export type MiningGetFleetLiveMapDetailResponse = {
  ID: Scalars['String']
  area: Scalars['String']
  capacity: Scalars['Float']
  cycleCount: Scalars['Int']
  driverName: Scalars['String']
  driverType: Scalars['String']
  engineHours: Scalars['Int']
  engineStatus: Scalars['Boolean']
  lastUnit: Scalars['String']
  materialName: Scalars['String']
  materialUom: Scalars['String']
  speed: Scalars['Float']
}

export type MiningListUnitHistoryParam = {
  count?: InputMaybe<Scalars['Int']>
  endDate?: InputMaybe<Scalars['String']>
  fleetID?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  startDate?: InputMaybe<Scalars['String']>
}

export type MiningListUnitParam = {
  disableUserOrgFilter?: InputMaybe<Scalars['Boolean']>
  filterBy?: InputMaybe<MiningUnitFilterType>
  filterByAssignment?: InputMaybe<VehicleFilterType>
  filterdriver?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  filterfleet?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  filterstatus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  filterunit?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  locationID?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<SortByFleet>
  sortOrder?: InputMaybe<SortOrder>
}

export type MiningLiveDispacthResponse = {
  Maintance?: Maybe<Array<Maybe<MiningDispacthDataMaintanceResponse>>>
  Parking?: Maybe<Array<Maybe<MiningDispacthParkingUnitReponse>>>
  Ready?: Maybe<Array<Maybe<MiningDispacthDataResponse>>>
}

export type MiningLocations = {
  detailSiteLocation: SiteLocationDetailResponse
  detailSubAreaLocation: SiteLocationDetailResponse
  listSiteLocation: LocationListResponse
  listSubAreaLocation: LocationListResponse
  miningCheckLocation: CrudResponse
  miningCheckLocationNoTenant: CrudResponse
  miningGetAllArea?: Maybe<AreaListResponse>
  miningGetDetailArea?: Maybe<DetailAreaResponse>
  miningGetLocationsList?: Maybe<LocationsList>
  miningGetSubAreaTypeBySiteID?: Maybe<
    Array<Maybe<SubAreaTypeBySiteIdResponse>>
  >
}

export type MiningLocationsDetailSiteLocationArgs = {
  id: Scalars['String']
}

export type MiningLocationsDetailSubAreaLocationArgs = {
  id: Scalars['String']
}

export type MiningLocationsListSiteLocationArgs = {
  param?: InputMaybe<LocationSiteListParam>
}

export type MiningLocationsListSubAreaLocationArgs = {
  param?: InputMaybe<LocationSiteListParam>
}

export type MiningLocationsMiningCheckLocationArgs = {
  siteId: Scalars['String']
  tenantID: Scalars['String']
}

export type MiningLocationsMiningCheckLocationNoTenantArgs = {
  siteId: Scalars['String']
}

export type MiningLocationsMiningGetAllAreaArgs = {
  param?: InputMaybe<GetAllAreaParam>
}

export type MiningLocationsMiningGetDetailAreaArgs = {
  id: Scalars['String']
}

export type MiningLocationsMiningGetLocationsListArgs = {
  param?: InputMaybe<LocationList>
}

export type MiningLocationsMiningGetSubAreaTypeBySiteIdArgs = {
  siteID: Scalars['String']
}

export type MiningLocationsMutation = {
  assignGroupSite: CrudResponse
  createSiteLocation: CrudResponse
  createSubAreaLocation: CrudResponse
  deleteGroupSite: CrudResponse
  deleteSiteLocation: CrudResponse
  deleteSubAreaLocation: CrudResponse
  minigDeleteArea: CrudResponse
  miningCreateArea: CrudResponse
  miningUpdateArea: CrudResponse
  miningUploadArea: UploadResponseArea
  updateSiteLocation: CrudResponse
  updateSubAreaLocation: CrudResponse
}

export type MiningLocationsMutationAssignGroupSiteArgs = {
  areaID: Scalars['String']
  groupID: Scalars['String']
}

export type MiningLocationsMutationCreateSiteLocationArgs = {
  param?: InputMaybe<CreateLocationParam>
}

export type MiningLocationsMutationCreateSubAreaLocationArgs = {
  param?: InputMaybe<LocationSubAreaParam>
}

export type MiningLocationsMutationDeleteGroupSiteArgs = {
  areaID: Scalars['String']
  groupID: Scalars['String']
}

export type MiningLocationsMutationDeleteSiteLocationArgs = {
  id: Scalars['String']
}

export type MiningLocationsMutationDeleteSubAreaLocationArgs = {
  id: Scalars['String']
}

export type MiningLocationsMutationMinigDeleteAreaArgs = {
  areaID: Scalars['String']
}

export type MiningLocationsMutationMiningCreateAreaArgs = {
  param?: InputMaybe<AreaParam>
}

export type MiningLocationsMutationMiningUpdateAreaArgs = {
  param?: InputMaybe<AreaParam>
}

export type MiningLocationsMutationMiningUploadAreaArgs = {
  file: Scalars['Upload']
}

export type MiningLocationsMutationUpdateSiteLocationArgs = {
  id: Scalars['String']
  param: LocationParam
}

export type MiningLocationsMutationUpdateSubAreaLocationArgs = {
  id: Scalars['String']
  param?: InputMaybe<LocationSubAreaParam>
}

export type MiningUnit = {
  engineStatus?: Maybe<Scalars['Boolean']>
  latitude?: Maybe<Scalars['String']>
  longitude?: Maybe<Scalars['String']>
  serverTime?: Maybe<Scalars['String']>
  speed?: Maybe<Scalars['Int']>
  time?: Maybe<Scalars['String']>
  voltage?: Maybe<Scalars['Int']>
}

export type MiningUnitData = {
  ID: Scalars['String']
  area: Scalars['String']
  assigmentStatus: Scalars['String']
  breakDownStart?: Maybe<Scalars['String']>
  capacity: Scalars['Float']
  coordinate: Coordinate
  digitalInput?: Maybe<Scalars['Int']>
  driverData?: Maybe<Array<DriverFleetAssigmentData>>
  engineHours?: Maybe<Scalars['String']>
  engineStatus: Scalars['Boolean']
  fault: Scalars['Int']
  fleet: Scalars['String']
  fleetType: Scalars['String']
  iotTime?: Maybe<Scalars['String']>
  lastUnit?: Maybe<Scalars['String']>
  material: Scalars['String']
  ritase: Scalars['String']
  speed: Scalars['Int']
  status: Scalars['String']
  statusColor: Scalars['String']
  statusType: Scalars['String']
  timeShift: Scalars['String']
  unitCode: Scalars['String']
  uom?: Maybe<Scalars['String']>
}

export type MiningUnitDataResponse = {
  data: Array<MiningUnitData>
  location: Scalars['String']
  tenantID: Scalars['String']
  time: Scalars['String']
}

export enum MiningUnitFilterType {
  Breakdown = 'BREAKDOWN',
  Delay = 'DELAY',
  Dumping = 'DUMPING',
  Dumptruck = 'DUMPTRUCK',
  Excavator = 'EXCAVATOR',
  Filling = 'FILLING',
  Hanging = 'HANGING',
  Load = 'LOAD',
  Maintance = 'MAINTANCE',
  None = 'NONE',
  Traveling = 'TRAVELING',
}

export type MiningUnitHistory = {
  dataHistory?: Maybe<Array<Maybe<MiningUnit>>>
  dataLoadingCoordinate?: Maybe<Array<Maybe<LoadingCoordinate>>>
  fleetID: Scalars['String']
  lastLatitudeRangeTime: Scalars['String']
  lastLongitudeRangeTime: Scalars['String']
  unitCode: Scalars['String']
  unitType: Scalars['String']
}

export type MiningUnitHistoryResponse = {
  data?: Maybe<Array<Maybe<MiningUnitHistory>>>
}

export type MiningUnitListParams = {
  filterBy?: InputMaybe<VehicleFilterType>
  locationID?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
}

export type MiningUnitParam = {
  filterUnitType?: InputMaybe<UnitTypeFilter>
  locationID?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
}

export type ModelListParam = {
  fleetTypeID?: InputMaybe<Scalars['String']>
  pagination?: InputMaybe<PaginationParam>
  search?: InputMaybe<Scalars['String']>
}

export type Mutation = {
  commands: CommandsMutation
  devices: DevicesMutation
  drivers: DriversMutation
  geofences: GeofencesMutation
  location: MiningLocationsMutation
  miningCycle: MiningCycleMutation
  references: ReferencesMutation
  vehicles: VehiclesMutation
  workshifts: WorkShiftsMutation
}

export enum OdometerSortType {
  Kilometer = 'KILOMETER',
  Name = 'NAME',
}

export enum OdometerType {
  Medan = 'MEDAN',
  Transjakarta = 'TRANSJAKARTA',
}

export type OutputData = {
  altitude: Scalars['Float']
  course: Scalars['Int']
  deviceTime: Scalars['Int']
  ignition: Scalars['Boolean']
  image?: Maybe<Scalars['String']>
  imei: Scalars['String']
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  movement: Scalars['Boolean']
  positioningTime: Scalars['Int']
  properties: Array<Properties>
  protocol: Scalars['String']
  serverTime: Scalars['Int']
  speed: Scalars['Int']
  status: Scalars['String']
}

export type PaginationParam = {
  active?: InputMaybe<Scalars['Boolean']>
  limit?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
}

export type PaginationResult = {
  before: Scalars['Int']
  current: Scalars['Int']
  first: Scalars['Int']
  last: Scalars['Int']
  next: Scalars['Int']
  perPage: Scalars['Int']
  totalPage: Scalars['Int']
  totalRecord: Scalars['Int']
}

export type PerformanceData = {
  Productivity?: Maybe<Scalars['Float']>
  ReadyTime?: Maybe<Scalars['String']>
  assetName?: Maybe<Scalars['String']>
  avgSpeed?: Maybe<Scalars['Float']>
  date?: Maybe<Scalars['String']>
  driverName?: Maybe<Scalars['String']>
  materialName?: Maybe<Scalars['String']>
}

export type PerformanceParam = {
  ID: Scalars['String']
  endTime: Scalars['String']
  pagination?: InputMaybe<PaginationParam>
  startTime: Scalars['String']
}

export type PerformanceResponse = {
  data: Array<PerformanceData>
  pagination?: Maybe<PaginationResult>
}

export enum PeriodTelemetry {
  First = 'FIRST',
  Last = 'LAST',
}

export type PolygonGeoJsonInput = {
  geometry: PolygonGeometryInput
  properties?: InputMaybe<GeoPropertiesInput>
  type: Scalars['String']
}

export type PolygonGeometry = {
  polygonCoordinates: Array<Array<Array<Scalars['Float']>>>
  type: Scalars['String']
}

export type PolygonGeometryInput = {
  coordinates: Array<Array<Array<Scalars['Float']>>>
  type: GeofenceType
}

export type Position = {
  altitude: Scalars['Float']
  course: Scalars['Int']
  deviceTime: Scalars['Int']
  ignition: Scalars['Boolean']
  image?: InputMaybe<Scalars['String']>
  imei: Scalars['String']
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  movement: Scalars['Boolean']
  positioningTime: Scalars['Int']
  properties: Array<InputMaybe<PropertiesInput>>
  protocol: Scalars['String']
  serverTime: Scalars['Int']
  speed: Scalars['Int']
  status: Scalars['String']
}

export type PositionData = {
  address: Scalars['String']
  altitude: Scalars['Float']
  battery: Scalars['Float']
  course: Scalars['Int']
  deviceTime: Scalars['String']
  event: Scalars['String']
  ignition: Scalars['Boolean']
  imei: Scalars['String']
  isBlackBox: Scalars['Boolean']
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  mcb: Scalars['Int']
  mileage: Scalars['Float']
  movement: Scalars['Boolean']
  place: Scalars['String']
  positioningTime: Scalars['String']
  properties?: Maybe<Array<Properties>>
  protocol: Scalars['String']
  remarks: Scalars['String']
  serverTime: Scalars['String']
  sim: Scalars['String']
  speed: Scalars['Int']
  speedLimit: Scalars['String']
  status: Scalars['String']
  temperature: Scalars['String']
}

export type PositionDataTrimmed = {
  ID: Scalars['String']
  Image: Scalars['String']
  battery: Scalars['String']
  fleetName: Scalars['String']
  latitude: Scalars['Float']
  licensePlate: Scalars['String']
  longitude: Scalars['Float']
  manufacture: Scalars['String']
  mode: Scalars['String']
  odometer: Scalars['String']
  remark: Scalars['String']
  speed: Scalars['String']
  status: Scalars['String']
  transmission: Scalars['String']
  vinNO: Scalars['String']
}

export type PositionHistoryResponse = {
  data: Array<PositionData>
  licensePlate: Scalars['String']
  tenant: Scalars['String']
  vinNo: Scalars['String']
}

export type PowerBiUrlResponse = {
  ID: Scalars['String']
  siteID: Scalars['String']
  url: Scalars['String']
}

export type ProductivityData = {
  Productivity?: Maybe<Scalars['Float']>
  cycleCount?: Maybe<Scalars['Int']>
  hourEnd?: Maybe<Scalars['Int']>
  hourStart?: Maybe<Scalars['Int']>
  minuteEnd?: Maybe<Scalars['Int']>
  minuteStart?: Maybe<Scalars['Int']>
}

export type ProductivityList = {
  assetID?: Maybe<Scalars['String']>
  assetName?: Maybe<Scalars['String']>
  data: Array<ProductivityData>
}

export type ProductivityParam = {
  assetType?: InputMaybe<FilterAssetType>
  endTime: Scalars['String']
  filterShift?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  filterUnit?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  locationID: Scalars['String']
  sortOrder?: InputMaybe<SortOrder>
  startTime: Scalars['String']
}

export type ProductivityResponse = {
  ProductivityList?: Maybe<Array<Maybe<ProductivityList>>>
  hours?: Maybe<Array<Maybe<HoursRow>>>
}

export type Properties = {
  field?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type PropertiesInput = {
  field?: InputMaybe<Scalars['String']>
  value?: InputMaybe<Scalars['String']>
}

export enum QcFilterType {
  Fail = 'FAIL',
  None = 'NONE',
  Pass = 'PASS',
}

export type Query = {
  devices: Devices
  drivers: Drivers
  geofences: Geofences
  mails: Mails
  miningCycle: MiningCycle
  miningLocation: MiningLocations
  references: References
  rules: Rules
  vehicles: Vehicles
  workshifts: WorkShifts
}

export type References = {
  detailMaterial: MaterialDetailResponse
  detailMaterialAssignment: MaterialAssigmentChild
  getAllAsset: AssetListResponse
  getAllBrands?: Maybe<AssetModelsResponse>
  getAllLocationTypeStatus: StatusListTypeResponse
  getAllModelByBrand?: Maybe<AssetModelsResponse>
  getAllParentStatus: StatusParentResponse
  getAllSiteStatus: SiteStatusListResponse
  getAllStatus: StatusListResponse
  getAllStatusList: StatusListResponse
  getAllTimeZone?: Maybe<TimeZoneListResponse>
  getAllTypeStatus: StatusListTypeResponse
  getAssetDetail?: Maybe<AssetDettailResponse>
  getDetailStatus?: Maybe<StatusDetailResponse>
  getLoadDetail?: Maybe<LoadFactorData>
  getLoadFactorList?: Maybe<LoadFactorListResponse>
  getMaterialAssignmentTabularView: MaterialTabularViews
  getPowerBIUrl: PowerBiUrlResponse
  getTimeZoneDetail?: Maybe<TimeZoneDetail>
  getUnassignDevice?: Maybe<DeviceDataResponse>
  listMaterial: MaterialListResponse
  listMaterialAssignment: MaterialAssigmentListResponse
  listMaterialAssignmentByArea: MaterialAssigmentListResponse
}

export type ReferencesDetailMaterialArgs = {
  id: Scalars['String']
}

export type ReferencesDetailMaterialAssignmentArgs = {
  id: Scalars['String']
}

export type ReferencesGetAllAssetArgs = {
  param?: InputMaybe<AssetListParams>
}

export type ReferencesGetAllModelByBrandArgs = {
  brand?: InputMaybe<Scalars['String']>
}

export type ReferencesGetAllParentStatusArgs = {
  param?: InputMaybe<StatusParentParam>
}

export type ReferencesGetAllSiteStatusArgs = {
  param: SiteStatusListParam
}

export type ReferencesGetAllStatusArgs = {
  param?: InputMaybe<StatusListParam>
}

export type ReferencesGetAllStatusListArgs = {
  param?: InputMaybe<StatusListParam>
}

export type ReferencesGetAssetDetailArgs = {
  assetID?: InputMaybe<Scalars['String']>
}

export type ReferencesGetDetailStatusArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type ReferencesGetLoadDetailArgs = {
  LoadFactorID: Scalars['String']
}

export type ReferencesGetLoadFactorListArgs = {
  param?: InputMaybe<LoadFactorListParam>
}

export type ReferencesGetMaterialAssignmentTabularViewArgs = {
  param: MaterialTabularViewsParams
}

export type ReferencesGetPowerBiUrlArgs = {
  siteID: Scalars['String']
}

export type ReferencesGetTimeZoneDetailArgs = {
  timezoneID?: InputMaybe<Scalars['String']>
}

export type ReferencesListMaterialArgs = {
  param?: InputMaybe<MaterialListParam>
}

export type ReferencesListMaterialAssignmentArgs = {
  param: MaterialAssignmentListParam
}

export type ReferencesListMaterialAssignmentByAreaArgs = {
  param: MaterialAssignmentListParam
}

export type ReferencesMutation = {
  assignMaterial: CrudResponse
  assignMaterialByArea: CrudResponse
  createAsset: CrudResponse
  createLoadFactor: CrudResponse
  createMaterial: CrudResponse
  createSiteStatus: CrudResponse
  createTimeZone: CrudResponse
  deleteAsset: CrudResponse
  deleteLoadFactor: CrudResponse
  deleteMaterial: CrudResponse
  deleteMaterialAssignment: CrudResponse
  deleteSiteStatus: CrudResponse
  deleteStatus: CrudResponse
  deleteTimeZone: CrudResponse
  insertDataStatus: CrudResponse
  updateAsset: CrudResponse
  updateDataStatus: CrudResponse
  updateLoadFactor: CrudResponse
  updateMaterial: CrudResponse
  updateMaterialAssignment: CrudResponse
  updateMaterialAssignmentByArea: CrudResponse
  updateSiteStatus: CrudResponse
  updateTimeZone: CrudResponse
}

export type ReferencesMutationAssignMaterialArgs = {
  param: AssignMaterialParam
}

export type ReferencesMutationAssignMaterialByAreaArgs = {
  param: AssignMaterialByAreaParam
}

export type ReferencesMutationCreateAssetArgs = {
  param: AssetParamsInsert
}

export type ReferencesMutationCreateLoadFactorArgs = {
  param: LoadFactorParams
}

export type ReferencesMutationCreateMaterialArgs = {
  param: MaterialParam
}

export type ReferencesMutationCreateSiteStatusArgs = {
  param: SiteStatusInput
}

export type ReferencesMutationCreateTimeZoneArgs = {
  param?: InputMaybe<TimeZoneParam>
}

export type ReferencesMutationDeleteAssetArgs = {
  assetID: Scalars['String']
}

export type ReferencesMutationDeleteLoadFactorArgs = {
  loadFactorID: Scalars['String']
}

export type ReferencesMutationDeleteMaterialArgs = {
  id: Scalars['String']
}

export type ReferencesMutationDeleteMaterialAssignmentArgs = {
  id: Scalars['String']
}

export type ReferencesMutationDeleteSiteStatusArgs = {
  id: Scalars['String']
}

export type ReferencesMutationDeleteStatusArgs = {
  id: Scalars['String']
}

export type ReferencesMutationDeleteTimeZoneArgs = {
  timezoneID?: InputMaybe<Scalars['String']>
}

export type ReferencesMutationInsertDataStatusArgs = {
  param?: InputMaybe<StatusParam>
}

export type ReferencesMutationUpdateAssetArgs = {
  param: AssetParamsUpdate
}

export type ReferencesMutationUpdateDataStatusArgs = {
  param?: InputMaybe<UpdateStatusparam>
}

export type ReferencesMutationUpdateLoadFactorArgs = {
  loadFactorID: Scalars['String']
  param: LoadFactorParams
}

export type ReferencesMutationUpdateMaterialArgs = {
  id: Scalars['String']
  param: MaterialParam
}

export type ReferencesMutationUpdateMaterialAssignmentArgs = {
  id: Scalars['String']
  param: AssignMaterialParam
}

export type ReferencesMutationUpdateMaterialAssignmentByAreaArgs = {
  id: Scalars['String']
  param: AssignMaterialByAreaParam
}

export type ReferencesMutationUpdateSiteStatusArgs = {
  id: Scalars['String']
  param: SiteStatusInput
}

export type ReferencesMutationUpdateTimeZoneArgs = {
  param?: InputMaybe<TimeZoneParam>
  timezoneID?: InputMaybe<Scalars['String']>
}

export type ReportCycleEventData = {
  eventCycleTimes?: Maybe<Array<Maybe<EventCycleTimes>>>
  unitID: Scalars['String']
  unitName: Scalars['String']
}

export type ReportCycleEventItem = {
  assetClass: Scalars['String']
  assetDescription: Scalars['String']
  assetID: Scalars['String']
  endTimestamp: Scalars['String']
  eventID: Scalars['String']
  eventLabel: Scalars['String']
  eventRemarks: Scalars['String']
  eventSubType: Scalars['String']
  eventType: Scalars['String']
  excavatorFleetID: Scalars['String']
  flag: Scalars['String']
  ritaseEndTime: Scalars['String']
  ritaseStartTime: Scalars['String']
  startTimestamp: Scalars['String']
}

export type ReportData = {
  ID: Scalars['String']
  date: Scalars['String']
  fileUrl: Scalars['String']
  fleetID: Scalars['String']
  fleetName: Scalars['String']
  requestedAt: Scalars['String']
  status: Scalars['String']
  type: Scalars['String']
}

export type ReportEventResponse = {
  pagination?: Maybe<PaginationResult>
  reportCycleEventData?: Maybe<Array<Maybe<ReportCycleEventData>>>
}

export type ReportEventResponseData = {
  lastUpdate?: Maybe<Scalars['String']>
  pagination?: Maybe<PaginationResult>
  reportCycleEventData?: Maybe<Array<Maybe<ReportEventResponseUpdate>>>
}

export type ReportEventResponseUpdate = {
  aseetName: Scalars['String']
  assetID: Scalars['String']
  items: Array<ReportCycleEventItem>
}

export type ReportFileData = {
  filename: Scalars['String']
  url: Scalars['String']
}

export type ReportFileList = {
  reportFileData: Array<ReportFileData>
}

export type ReportListResponse = {
  data: Array<ReportData>
  pagination?: Maybe<PaginationResult>
}

export type ReportParam = {
  date: Scalars['String']
  fleetID: Scalars['String']
  type: Scalars['String']
}

export type ReprocessCycleRequest = {
  createdAt?: Maybe<Scalars['String']>
  endTime?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  taskEndTime?: Maybe<Scalars['String']>
  taskStartTime?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type ReprocessCycleRequestList = {
  data: Array<ReprocessCycleRequest>
}

export enum ReprocessCycleType {
  Activity = 'ACTIVITY',
  Cycle = 'CYCLE',
}

export type RouteData = {
  distance: Scalars['Float']
  endTime: Scalars['String']
  route: Scalars['String']
  startTime: Scalars['String']
}

export type RouteResponse = {
  fleetCycleSummary: Array<FleetCycleSummary>
  routeData: Array<RouteData>
}

export enum RouteType {
  All = 'All',
  Pool = 'Pool',
  Route = 'Route',
  Station = 'Station',
}

export type Rules = {
  processRules: RulesResponse
}

export type RulesProcessRulesArgs = {
  param: InputData
}

export type RulesResponse = {
  data: OutputData
  success: Scalars['Boolean']
}

export type ShiftDelayData = {
  duration?: Maybe<Scalars['String']>
  endTime?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['String']>
}

export type ShiftHistoryData = {
  duration?: Maybe<Scalars['String']>
  endTime?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['String']>
}

export type ShiftHistoryParam = {
  endTime: Scalars['String']
  pagination?: InputMaybe<PaginationParam>
  startTime: Scalars['String']
}

export type ShiftHistoryResponse = {
  data: Array<ShiftHistoryData>
  delayData: Array<ShiftDelayData>
  pagination?: Maybe<PaginationResult>
}

export type ShiftResponse = {
  active: Scalars['Boolean']
  duration: Scalars['String']
  fleetID?: Maybe<Scalars['String']>
  fleetName: Scalars['String']
  startTime: Scalars['String']
}

export type SiteCoordinate = {
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}

export type SiteListParam = {
  companyID: Scalars['String']
}

export type SiteLocationDetailResponse = {
  data: Location
}

export type SiteStatusData = {
  CreatedAt?: Maybe<Scalars['String']>
  ID?: Maybe<Scalars['String']>
  createdBy?: Maybe<Scalars['String']>
  locationName?: Maybe<Scalars['String']>
  statusName?: Maybe<Scalars['String']>
}

export type SiteStatusInput = {
  siteID: Scalars['String']
  statusID: Scalars['String']
}

export type SiteStatusListParam = {
  locationID?: InputMaybe<Scalars['String']>
  pagination?: InputMaybe<PaginationParam>
  search?: InputMaybe<Scalars['String']>
}

export type SiteStatusListResponse = {
  data?: Maybe<Array<Maybe<SiteStatusData>>>
  pagination?: Maybe<PaginationResult>
}

export enum SortBy {
  Battery = 'BATTERY',
  Imei = 'IMEI',
  ManufactureDate = 'MANUFACTURE_DATE',
  None = 'NONE',
  QcDate = 'QC_DATE',
  UpdatedAt = 'UPDATED_AT',
}

export enum SortByArea {
  SortAreaCode = 'SORT_AREA_CODE',
  SortAreaCreatedOn = 'SORT_AREA_CREATED_ON',
  SortAreaName = 'SORT_AREA_NAME',
  SortAreaNone = 'SORT_AREA_NONE',
  SortAreaUpdatedAt = 'SORT_AREA_UPDATED_AT',
}

export enum SortByCycleReport {
  SortCycleReprotNone = 'SORT_CYCLE_REPROT_NONE',
  SortCycleReprotStartTime = 'SORT_CYCLE_REPROT_START_TIME',
}

export enum SortByFleet {
  SortFleetName = 'SORT_FLEET_NAME',
  SortNone = 'SORT_NONE',
}

export enum SortByLoadFactor {
  SortLoadFactorCreatedOn = 'SORT_LOAD_FACTOR_CREATED_ON',
  SortLoadFactorNone = 'SORT_LOAD_FACTOR_NONE',
  SortLoadFactorUpdatedOn = 'SORT_LOAD_FACTOR_UPDATED_ON',
}

export enum SortByMasterDevices {
  SortMasterDevicesCreatedAt = 'SORT_MASTER_DEVICES_CREATED_AT',
  SortMasterDevicesNone = 'SORT_MASTER_DEVICES_NONE',
  SortMasterDevicesUpdatedAt = 'SORT_MASTER_DEVICES_UPDATED_AT',
}

export enum SortByMasterMaterial {
  SortMasterMaterialCreatedAt = 'SORT_MASTER_MATERIAL_CREATED_AT',
  SortMasterMaterialNone = 'SORT_MASTER_MATERIAL_NONE',
  SortMasterMaterialUpdatedAt = 'SORT_MASTER_MATERIAL_UPDATED_AT',
}

export enum SortByMaterialAssignment {
  SortMaterialAssignmentCreatedOn = 'SORT_MATERIAL_ASSIGNMENT_CREATED_ON',
  SortMaterialAssignmentEndTime = 'SORT_MATERIAL_ASSIGNMENT_END_TIME',
  SortMaterialAssignmentNone = 'SORT_MATERIAL_ASSIGNMENT_NONE',
  SortMaterialAssignmentStartTime = 'SORT_MATERIAL_ASSIGNMENT_START_TIME',
  SortMaterialAssignmentUpdatedOn = 'SORT_MATERIAL_ASSIGNMENT_UPDATED_ON',
}

export enum SortBySite {
  SortSiteCode = 'SORT_SITE_CODE',
  SortSiteCreatedOn = 'SORT_SITE_CREATED_ON',
  SortSiteName = 'SORT_SITE_NAME',
  SortSiteNone = 'SORT_SITE_NONE',
  SortSiteUpdatedAt = 'SORT_SITE_UPDATED_AT',
}

export enum SortBySubArea {
  SortSubAreaCode = 'SORT_SUB_AREA_CODE',
  SortSubAreaCreatedOn = 'SORT_SUB_AREA_CREATED_ON',
  SortSubAreaName = 'SORT_SUB_AREA_NAME',
  SortSubAreaNone = 'SORT_SUB_AREA_NONE',
  SortSubAreaUpdatedAt = 'SORT_SUB_AREA_UPDATED_AT',
}

export enum SortByVehicles {
  Battery = 'BATTERY',
  FleetName = 'FLEET_NAME',
  Imei = 'IMEI',
  Mcb = 'MCB',
  None = 'NONE',
  Sim = 'SIM',
  Time = 'TIME',
  VinNo = 'VIN_NO',
}

export enum SortByWorkShift {
  SortWorkshiftCreatedOn = 'SORT_WORKSHIFT_CREATED_ON',
  SortWorkshiftNone = 'SORT_WORKSHIFT_NONE',
  SortWorkshiftUpdatedAt = 'SORT_WORKSHIFT_UPDATED_AT',
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SpeedData = {
  data: Array<SpeedDetails>
  fleetID: Scalars['String']
}

export type SpeedDetails = {
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  time: Scalars['String']
}

export type SpeedParam = {
  assetType?: InputMaybe<FilterAssetType>
  endTime: Scalars['String']
  isRange: Scalars['Boolean']
  maxSpeed?: InputMaybe<Scalars['Int']>
  minSpeed: Scalars['Int']
  siteID: Scalars['String']
  startTime: Scalars['String']
}

export type SpeedResponse = {
  data: Array<SpeedData>
}

export type StatusData = {
  ChildData?: Maybe<Array<Maybe<ChildData>>>
  ID?: Maybe<Scalars['String']>
  active?: Maybe<Scalars['Boolean']>
  createdAt?: Maybe<Scalars['String']>
  createdBy?: Maybe<Scalars['String']>
  enabled?: Maybe<Scalars['Boolean']>
  label?: Maybe<Scalars['String']>
  modifiedAt?: Maybe<Scalars['String']>
  modifiedBy?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  parent?: Maybe<Scalars['String']>
  parentStatus?: Maybe<Scalars['String']>
  statusColor?: Maybe<Scalars['String']>
  statusType?: Maybe<Scalars['String']>
}

export type StatusDetailResponse = {
  data?: Maybe<StatusData>
}

export type StatusListParam = {
  filterCreatedBy?: InputMaybe<Scalars['String']>
  filterCreatedOn?: InputMaybe<Scalars['String']>
  filterParentStatus?: InputMaybe<Scalars['String']>
  filterStatusType?: InputMaybe<Scalars['String']>
  pagination?: InputMaybe<PaginationParam>
  search?: InputMaybe<Scalars['String']>
}

export type StatusListResponse = {
  data?: Maybe<Array<Maybe<StatusData>>>
  lastUpdated?: Maybe<Scalars['String']>
  pagination?: Maybe<PaginationResult>
}

export type StatusListTypeResponse = {
  data?: Maybe<Array<Maybe<StatusData>>>
}

export type StatusParam = {
  enabled: Scalars['Boolean']
  label: Scalars['String']
  parent: Scalars['String']
  parentStatus: Scalars['String']
  statusColor: Scalars['String']
  statusType: Scalars['String']
}

export type StatusParentParam = {
  statusType?: InputMaybe<Scalars['String']>
}

export type StatusParentResponse = {
  data?: Maybe<Array<Maybe<StatusData>>>
}

export enum StatusTargetValue {
  ActivatedName = 'ACTIVATED_NAME',
  ActivationReadyName = 'ACTIVATION_READY_NAME',
  DeactivatedName = 'DEACTIVATED_NAME',
  InventoryName = 'INVENTORY_NAME',
  PurgedName = 'PURGED_NAME',
  RetiredName = 'RETIRED_NAME',
  TestReadyName = 'TEST_READY_NAME',
  TrialName = 'TRIAL_NAME',
}

export type SubAreaTypeBySiteIdResponse = {
  ID?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  subAreaType?: Maybe<Scalars['String']>
  tenantID?: Maybe<Scalars['String']>
}

export type SubscriptionHistoryData = {
  duration: Scalars['String']
  end: Scalars['String']
  remark: Scalars['String']
  start: Scalars['String']
  status: Scalars['String']
  time?: Maybe<Scalars['String']>
}

export type SubscriptionHistoryResponse = {
  data: Array<SubscriptionHistoryData>
  duration: Array<DurationData>
}

export enum SubscriptionSortBy {
  DataUsage = 'DATA_USAGE',
  QcEnd = 'QC_END',
  QcStart = 'QC_START',
  SubscriptionEnd = 'SUBSCRIPTION_END',
  SubscriptionStart = 'SUBSCRIPTION_START',
}

export type SubscriptionsData = {
  ID: Scalars['String']
  active: Scalars['Boolean']
  client: Scalars['String']
  deviceID: Scalars['String']
  iccid: Scalars['String']
  imei: Scalars['String']
  provider: Scalars['String']
  qcEnd: Scalars['String']
  qcStart: Scalars['String']
  sim: Scalars['String']
  status: Scalars['String']
  subscriptionEnd: Scalars['String']
  subscriptionStart: Scalars['String']
  usage: Scalars['Float']
  vinNO: Scalars['String']
}

export enum SubscriptionsFilterBy {
  None = 'NONE',
  SubscriptionActive = 'SUBSCRIPTION_ACTIVE',
  SubscriptionInactive = 'SUBSCRIPTION_INACTIVE',
}

export type SubscriptionsListParam = {
  date?: InputMaybe<DateRangeSubscription>
  pagination?: InputMaybe<PaginationParam>
  search?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<SubscriptionSortBy>
  sortOrder?: InputMaybe<SortOrder>
  subscriptionsFilterBy?: InputMaybe<SubscriptionsFilterBy>
  testFilterBy?: InputMaybe<TestFilterBy>
}

export type SubscriptionsListResponse = {
  data: Array<SubscriptionsData>
  lastUpdate: Scalars['String']
  pagination?: Maybe<PaginationResult>
}

export type SubscriptionsParam = {
  endDate: Scalars['String']
  remark?: InputMaybe<Scalars['String']>
  startDate: Scalars['String']
}

export enum SubscriptionsRangeType {
  None = 'NONE',
  SubscriptionEnd = 'SUBSCRIPTION_END',
  SubscriptionStart = 'SUBSCRIPTION_START',
  TestDataEnd = 'TEST_DATA_END',
  TestDataStart = 'TEST_DATA_START',
}

export type ThrDetailData = {
  adress: Scalars['String']
  badge: Scalars['String']
  driver: Scalars['String']
  ignition: Scalars['Boolean']
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  place: Scalars['String']
  rfid: Scalars['String']
  speed: Scalars['Float']
  speedLimit: Scalars['Float']
  temperature: Scalars['Float']
  time: Scalars['String']
}

export type ThrDetailResponse = {
  data: Array<ThrDetailData>
  pagination?: Maybe<PaginationResult>
}

export type ThrDrivingResponse = {
  addressEnd: Scalars['String']
  adressStart: Scalars['String']
  avgSpeed: Scalars['Float']
  badge: Scalars['String']
  distance: Scalars['Float']
  driver: Scalars['String']
  duration: Scalars['String']
  endTime: Scalars['String']
  rfid: Scalars['String']
  startTime: Scalars['String']
  topSpeed: Scalars['Float']
}

export type ThrEventResponse = {
  adress: Scalars['String']
  badge: Scalars['String']
  driver: Scalars['String']
  event: Scalars['String']
  ignition?: Maybe<Scalars['Boolean']>
  speed: Scalars['Float']
  time: Scalars['String']
}

export type ThrGeofenceEventsResponse = {
  address: Scalars['String']
  badge: Scalars['String']
  category: Scalars['String']
  dir: Scalars['String']
  driver: Scalars['String']
  name: Scalars['String']
  time: Scalars['String']
}

export type ThrReverseResponse = {
  adress: Scalars['String']
  area: Scalars['String']
  avgSpeed: Scalars['Float']
  badge: Scalars['String']
  distance: Scalars['Float']
  driver: Scalars['String']
  duration: Scalars['String']
  endTime: Scalars['String']
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  startTime: Scalars['String']
  topSpeed: Scalars['Float']
}

export type ThrSpeedingResponse = {
  adress: Scalars['String']
  area: Scalars['String']
  badge: Scalars['String']
  distance: Scalars['Float']
  driver: Scalars['String']
  duration: Scalars['String']
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  speedLimit: Scalars['Float']
  time: Scalars['String']
  topSpeed: Scalars['Float']
}

export type TamperingData = {
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  time: Scalars['String']
}

export type TamperingResponse = {
  data: Array<TamperingData>
  total: Scalars['Int']
}

export enum TestFilterBy {
  None = 'NONE',
  TestActive = 'TEST_ACTIVE',
  TestInactive = 'TEST_INACTIVE',
}

export type TestListParam = {
  pagination?: InputMaybe<PaginationParam>
  search?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<TestSortBy>
  sortOrder?: InputMaybe<SortOrder>
  statusFilter?: InputMaybe<TestStatusFilter>
  typeFilter?: InputMaybe<TestTypeFilter>
}

export type TestListResponse = {
  data: Array<TestRecordData>
  lastUpdate: Scalars['String']
  pagination?: Maybe<PaginationResult>
}

export type TestRecordData = {
  imei: Scalars['String']
  last_date_received: Scalars['String']
  properties: Array<Properties>
  remarks: Scalars['String']
  status: Scalars['String']
  time: Scalars['String']
  type: Scalars['String']
  vin_no: Scalars['String']
}

export type TestRecordParam = {
  imei?: InputMaybe<Scalars['String']>
  last_date_received?: InputMaybe<Scalars['String']>
  properties?: InputMaybe<Array<InputMaybe<PropertiesInput>>>
  remarks?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
  vin_no?: InputMaybe<Scalars['String']>
}

export enum TestSortBy {
  Imei = 'IMEI',
  LastData = 'LAST_DATA',
  Time = 'TIME',
}

export enum TestStatusFilter {
  None = 'NONE',
  Offline = 'OFFLINE',
  Online = 'ONLINE',
}

export enum TestTypeFilter {
  Cs = 'CS',
  None = 'NONE',
  Pdi = 'PDI',
  Qc = 'QC',
}

export type TimeZoneDetail = {
  data?: Maybe<TimeZonesData>
}

export type TimeZoneListResponse = {
  data?: Maybe<Array<Maybe<TimeZonesData>>>
}

export type TimeZoneParam = {
  active?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
}

export type TimeZonesData = {
  active?: Maybe<Scalars['Boolean']>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  modifiedAt?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  offset: Scalars['Int']
  timezone?: Maybe<Scalars['String']>
}

export type TimelineData = {
  address: Scalars['String']
  avgSpeed: Scalars['String']
  distance: Scalars['String']
  driver: Scalars['String']
  duration: Scalars['String']
  endTime: Scalars['String']
  startTime: Scalars['String']
  topSpeed: Scalars['String']
  type: Scalars['String']
}

export type TimelineResponse = {
  data?: Maybe<Array<TimelineData>>
}

export type TokenResponse = {
  driverID: Scalars['String']
  driverName: Scalars['String']
  expireAt: Scalars['Int']
  lastVehicle: LastVehicle
  locationID: Scalars['String']
  needChangePin: Scalars['Boolean']
  offset: Scalars['Int']
  token: Scalars['String']
}

export type TravelData = {
  name?: Maybe<Scalars['String']>
  target?: Maybe<Scalars['Float']>
  travelled?: Maybe<Scalars['Float']>
}

export type TravelParam = {
  customer?: InputMaybe<OdometerType>
  endDate?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<OdometerSortType>
  sortOrder?: InputMaybe<SortOrder>
  startDate?: InputMaybe<Scalars['String']>
  type?: InputMaybe<TravelType>
}

export type TravelResponse = {
  pagination?: Maybe<PaginationResult>
  travelData: Array<TravelData>
}

export enum TravelType {
  KmBaku = 'KM_BAKU',
  Odometer = 'ODOMETER',
}

export type TripData = {
  distance: Scalars['String']
  duration: Scalars['String']
  endCoordinate: Scalars['String']
  endTime: Scalars['String']
  startCoordinate: Scalars['String']
  startTime: Scalars['String']
}

export type TripHistoryPositionData = {
  address: Scalars['String']
  badge: Scalars['String']
  course: Scalars['Int']
  driver: Scalars['String']
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  positioningTime: Scalars['String']
  reverse: Scalars['Boolean']
  rfid: Scalars['String']
  speed: Scalars['Float']
}

export type TripHistoryResponse = {
  avgSpeed: Scalars['Float']
  batteryData: Array<BatteryData>
  distance: Scalars['Float']
  driver: Scalars['String']
  duration: Scalars['String']
  endTime: Scalars['String']
  ignition: Scalars['Boolean']
  positions: Array<TripHistoryPositionData>
  startTime: Scalars['String']
  topSpeed: Scalars['Float']
}

export type TripNightimeResponse = {
  count: Scalars['Int']
  data?: Maybe<Array<TripHistoryResponse>>
}

export enum UnitTypeFilter {
  Dumptruck = 'DUMPTRUCK',
  Excavator = 'EXCAVATOR',
  None = 'NONE',
}

export type UpdateStatusparam = {
  ID: Scalars['String']
  enabled: Scalars['Boolean']
  label: Scalars['String']
  parent?: InputMaybe<Scalars['String']>
  parentStatus?: InputMaybe<Scalars['String']>
  statusColor: Scalars['String']
  statusType: Scalars['String']
}

export type UploadResponseArea = {
  imageUrl?: Maybe<Scalars['String']>
  message: Scalars['String']
  success: Scalars['Boolean']
}

export type UserResponse = {
  message: Scalars['String']
  success: Scalars['Boolean']
}

export type VehicleData = {
  ID: Scalars['String']
  active: Scalars['Boolean']
  deviceID: Scalars['String']
  deviceModel: Scalars['String']
  deviceModelID: Scalars['String']
  driver: Scalars['String']
  driverID: Scalars['String']
  driverList: Array<DriverData>
  fleetModels: Scalars['String']
  fleetModelsID: Scalars['String']
  fleetName: Scalars['String']
  fleetType: Scalars['String']
  fleetTypeID?: Maybe<Scalars['String']>
  image: Scalars['String']
  imei: Scalars['String']
  licensePlate: Scalars['String']
  properties?: Maybe<Array<Properties>>
  remark: Scalars['String']
  status: Scalars['String']
  tags?: Maybe<Array<Properties>>
  tenantID: Scalars['String']
  vinNO: Scalars['String']
}

export enum VehicleFilterType {
  Assigned = 'ASSIGNED',
  None = 'NONE',
  Unassigned = 'UNASSIGNED',
}

export type VehicleLastPositionData = {
  position: PositionData
  vehicle: VehicleData
}

export type VehicleListParam = {
  filterBy?: InputMaybe<VehicleFilterType>
  pagination?: InputMaybe<PaginationParam>
  search?: InputMaybe<Scalars['String']>
}

export type VehicleModelData = {
  ID: Scalars['String']
  active: Scalars['Boolean']
  brand: Scalars['String']
  fleetTypeID: Scalars['String']
  model: Scalars['String']
}

export type VehicleModelsResponse = {
  data: Array<VehicleModelData>
  pagination?: Maybe<PaginationResult>
}

export type VehicleParam = {
  fleetModels: Scalars['String']
  fleetName: Scalars['String']
  image?: InputMaybe<Scalars['String']>
  imei?: InputMaybe<Scalars['String']>
  licensePlate: Scalars['String']
  make: Scalars['String']
  remark?: InputMaybe<Scalars['String']>
  vinNO: Scalars['String']
  year: Scalars['String']
}

export type VehicleTypesData = {
  ID: Scalars['String']
  active: Scalars['Boolean']
  description: Scalars['String']
  name: Scalars['String']
}

export type VehicleTypesResponse = {
  data: Array<VehicleTypesData>
  pagination?: Maybe<PaginationResult>
}

export type Vehicles = {
  GetDetailCycleEventByID: CycleEventDetailResponse
  GetDrivingScore: DrivingScoreResponse
  GetHeatmap: HeatmapResponse
  GetLatency: Array<LatencyData>
  GetOdomoterRecords: TravelResponse
  GetRouteList: RouteResponse
  GetSpeedMap: Array<SpeedResponse>
  GetTimeline: TimelineResponse
  GetVehiclesReportListS3: ReportFileList
  ListCycleEventTabular: CycleEventListResponse
  ListExcessiveIdling: ExcessiveIdlingResponse
  ListTampering: TamperingResponse
  MiningCycleReport: MiningCycleReportDataResponse
  MiningCycleReportFile: Scalars['String']
  MiningFetchFleetsLiveMap: MiningFetchFleetsLiveMapResponse
  MiningGetFleetLiveMapDetail: MiningGetFleetLiveMapDetailResponse
  MiningListUnit: MiningUnitDataResponse
  MiningListUnitHistory: MiningUnitHistoryResponse
  MiningListVehiclesUnit: MiningUnitDataResponse
  MiningLiveDispacth: MiningLiveDispacthResponse
  ReportEvent: ReportEventResponse
  ReportEventUpdate: ReportEventResponseData
  detailVehicles: VehiclesDetailResponse
  getDashboardStatictic: DashboardStatisticResponse
  getReport: Array<TripData>
  idleHistory?: Maybe<Array<TripHistoryResponse>>
  ignitionHistory?: Maybe<Array<TripHistoryResponse>>
  isNeedAccessRemark: Scalars['Boolean']
  lastPosition: LastPositionData
  lastPositionList: LastPositionResponse
  lastPositionListTrimmed: LastPositionTrimmedResponse
  listAccessHistory: AccessHistoryResponse
  listNighttimeAlert: TripNightimeResponse
  listReport: ReportListResponse
  listTHRDetail: ThrDetailResponse
  listTHRDriving: Array<ThrDrivingResponse>
  listTHREvent: Array<ThrEventResponse>
  listTHRSpeed: Array<ThrSpeedingResponse>
  listTHRrashDriving: Array<ThrEventResponse>
  listTHRreverse: Array<ThrReverseResponse>
  listVehicleModels: VehicleModelsResponse
  listVehicleTypes: VehicleTypesResponse
  listVehicles: LastPositionResponse
  positionHistory: PositionHistoryResponse
  stopHistory?: Maybe<Array<TripHistoryResponse>>
  tripHistory?: Maybe<Array<TripHistoryResponse>>
}

export type VehiclesGetDetailCycleEventByIdArgs = {
  eventID: Scalars['String']
}

export type VehiclesGetDrivingScoreArgs = {
  param?: InputMaybe<HistoryParam>
}

export type VehiclesGetHeatmapArgs = {
  param?: InputMaybe<HeatmapParam>
}

export type VehiclesGetLatencyArgs = {
  param?: InputMaybe<LatencyParam>
}

export type VehiclesGetOdomoterRecordsArgs = {
  pagination?: InputMaybe<PaginationParam>
  param?: InputMaybe<TravelParam>
}

export type VehiclesGetRouteListArgs = {
  date: Scalars['String']
  id: Scalars['String']
}

export type VehiclesGetSpeedMapArgs = {
  param?: InputMaybe<SpeedParam>
}

export type VehiclesGetTimelineArgs = {
  param?: InputMaybe<HistoryParam>
}

export type VehiclesGetVehiclesReportListS3Args = {
  id: Scalars['String']
}

export type VehiclesListCycleEventTabularArgs = {
  param?: InputMaybe<CycleEventListParam>
}

export type VehiclesListExcessiveIdlingArgs = {
  param?: InputMaybe<HistoryParam>
}

export type VehiclesListTamperingArgs = {
  param?: InputMaybe<HistoryParam>
}

export type VehiclesMiningCycleReportArgs = {
  param?: InputMaybe<MiningCycleReportDataParam>
}

export type VehiclesMiningCycleReportFileArgs = {
  param?: InputMaybe<MiningCycleReportDataParam>
}

export type VehiclesMiningFetchFleetsLiveMapArgs = {
  param?: InputMaybe<MiningFetchFleetsLiveMapParam>
}

export type VehiclesMiningGetFleetLiveMapDetailArgs = {
  id: Scalars['String']
}

export type VehiclesMiningListUnitArgs = {
  param?: InputMaybe<MiningUnitParam>
}

export type VehiclesMiningListUnitHistoryArgs = {
  param?: InputMaybe<MiningListUnitHistoryParam>
}

export type VehiclesMiningListVehiclesUnitArgs = {
  param?: InputMaybe<MiningListUnitParam>
}

export type VehiclesMiningLiveDispacthArgs = {
  isLive: Scalars['Boolean']
  param?: InputMaybe<LiveDispatchParam>
  siteId: Scalars['String']
}

export type VehiclesReportEventArgs = {
  param?: InputMaybe<EventParam>
}

export type VehiclesReportEventUpdateArgs = {
  param?: InputMaybe<EventParam>
}

export type VehiclesDetailVehiclesArgs = {
  id: Scalars['String']
}

export type VehiclesGetDashboardStaticticArgs = {
  param?: InputMaybe<HistoryParam>
}

export type VehiclesGetReportArgs = {
  filename: Scalars['String']
}

export type VehiclesIdleHistoryArgs = {
  param?: InputMaybe<HistoryParam>
}

export type VehiclesIgnitionHistoryArgs = {
  param?: InputMaybe<HistoryParam>
}

export type VehiclesIsNeedAccessRemarkArgs = {
  id: Scalars['String']
  type: AccessType
}

export type VehiclesLastPositionArgs = {
  id: Scalars['String']
}

export type VehiclesLastPositionListArgs = {
  param?: InputMaybe<LastPositionListParam>
}

export type VehiclesLastPositionListTrimmedArgs = {
  param?: InputMaybe<LastPositionListTrimmedParam>
}

export type VehiclesListAccessHistoryArgs = {
  param?: InputMaybe<AccessHistoryParam>
}

export type VehiclesListNighttimeAlertArgs = {
  param?: InputMaybe<HistoryParam>
}

export type VehiclesListReportArgs = {
  param?: InputMaybe<ListParam>
}

export type VehiclesListThrDetailArgs = {
  pagination?: InputMaybe<PaginationParam>
  param?: InputMaybe<HistoryParam>
}

export type VehiclesListThrDrivingArgs = {
  param?: InputMaybe<HistoryParam>
}

export type VehiclesListThrEventArgs = {
  param?: InputMaybe<HistoryParam>
}

export type VehiclesListThrSpeedArgs = {
  param?: InputMaybe<HistoryParam>
}

export type VehiclesListThRrashDrivingArgs = {
  param?: InputMaybe<HistoryParam>
}

export type VehiclesListThRreverseArgs = {
  param?: InputMaybe<HistoryParam>
}

export type VehiclesListVehicleModelsArgs = {
  param?: InputMaybe<ModelListParam>
}

export type VehiclesListVehicleTypesArgs = {
  param?: InputMaybe<ListParam>
}

export type VehiclesListVehiclesArgs = {
  param?: InputMaybe<LastPositionListParam>
}

export type VehiclesPositionHistoryArgs = {
  param?: InputMaybe<HistoryTimeParam>
}

export type VehiclesStopHistoryArgs = {
  param?: InputMaybe<HistoryParam>
}

export type VehiclesTripHistoryArgs = {
  param?: InputMaybe<HistoryParam>
}

export type VehiclesDetailResponse = {
  data?: Maybe<VehicleData>
}

export type VehiclesListResponse = {
  data: Array<VehicleData>
  pagination?: Maybe<PaginationResult>
}

export type VehiclesMutation = {
  DeleteCycleEvent: CrudResponse
  DeleteVehicleImage: CrudResponse
  InputCycleEvent: CrudResponse
  UpdateCycleEvent: CrudResponse
  UploadVehicleImage: File
  addVehicleToGroup: CrudResponse
  createVehicleAccessHistory: CrudResponse
  createVehicles: CrudResponse
  deleteVehicles: CrudResponse
  requestReport: ReportData
  singleUpload: File
  updateVehicles: CrudResponse
}

export type VehiclesMutationDeleteCycleEventArgs = {
  eventID: Scalars['String']
}

export type VehiclesMutationDeleteVehicleImageArgs = {
  id: Scalars['String']
}

export type VehiclesMutationInputCycleEventArgs = {
  param?: InputMaybe<CycleEventParam>
}

export type VehiclesMutationUpdateCycleEventArgs = {
  eventID: Scalars['String']
  param?: InputMaybe<CycleEventParam>
}

export type VehiclesMutationUploadVehicleImageArgs = {
  id: Scalars['String']
  in: Scalars['Upload']
}

export type VehiclesMutationAddVehicleToGroupArgs = {
  id: Scalars['String']
  orgid: Scalars['String']
}

export type VehiclesMutationCreateVehicleAccessHistoryArgs = {
  id: Scalars['String']
  remark: Scalars['String']
  type: AccessType
}

export type VehiclesMutationCreateVehiclesArgs = {
  param: VehicleParam
}

export type VehiclesMutationDeleteVehiclesArgs = {
  id: Scalars['String']
}

export type VehiclesMutationRequestReportArgs = {
  param?: InputMaybe<ReportParam>
}

export type VehiclesMutationSingleUploadArgs = {
  in: Scalars['Upload']
}

export type VehiclesMutationUpdateVehiclesArgs = {
  id: Scalars['String']
  param: VehicleParam
}

export type WebhookUrlData = {
  id: Scalars['String']
  key: Scalars['String']
  url: Scalars['String']
}

export type WebhookUrlInput = {
  key: Scalars['String']
  url: Scalars['String']
}

export type WebhookUrlResponse = {
  data: Array<WebhookUrlData>
  pagination?: Maybe<PaginationResult>
}

export type WorkShifListResponse = {
  data?: Maybe<Array<Maybe<WorkShiftData>>>
  lastUpdate?: Maybe<Scalars['String']>
  pagination?: Maybe<PaginationResult>
}

export type WorkShiftByLocationIdResponse = {
  data?: Maybe<Array<Maybe<WorkShiftData>>>
}

export type WorkShiftData = {
  ID?: Maybe<Scalars['String']>
  active?: Maybe<Scalars['Boolean']>
  code?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  createdBy?: Maybe<Scalars['String']>
  enabled?: Maybe<Scalars['Boolean']>
  endTime?: Maybe<Scalars['String']>
  locationID?: Maybe<Scalars['String']>
  locationName?: Maybe<Scalars['String']>
  modifiedAt?: Maybe<Scalars['String']>
  modifiedBy?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['String']>
  tenantID?: Maybe<Scalars['String']>
}

export type WorkShiftDetailResponse = {
  data?: Maybe<WorkShiftData>
}

export type WorkShiftListParam = {
  SortByWorkShift?: InputMaybe<SortByWorkShift>
  pagination?: InputMaybe<PaginationParam>
  search?: InputMaybe<Scalars['String']>
  siteID?: InputMaybe<Scalars['String']>
  sortOrder?: InputMaybe<SortOrder>
}

export type WorkShiftParam = {
  code: Scalars['String']
  enabled: Scalars['Boolean']
  endTime: Scalars['String']
  locationID: Scalars['String']
  name: Scalars['String']
  startTime: Scalars['String']
  tenantID: Scalars['String']
}

export type WorkShifts = {
  ListProductivity: ProductivityResponse
  ListWorkShift: WorkShifListResponse
  ListWorkShiftByLocationID: WorkShiftByLocationIdResponse
  detailWorkShift: WorkShiftDetailResponse
}

export type WorkShiftsListProductivityArgs = {
  param: ProductivityParam
}

export type WorkShiftsListWorkShiftArgs = {
  param?: InputMaybe<WorkShiftListParam>
}

export type WorkShiftsListWorkShiftByLocationIdArgs = {
  siteID: Scalars['String']
}

export type WorkShiftsDetailWorkShiftArgs = {
  id: Scalars['String']
}

export type WorkShiftsMutation = {
  createWorkShift: CrudResponse
  deleteWorkShift: CrudResponse
  updateWorkShift: CrudResponse
}

export type WorkShiftsMutationCreateWorkShiftArgs = {
  param: WorkShiftParam
}

export type WorkShiftsMutationDeleteWorkShiftArgs = {
  id: Scalars['String']
}

export type WorkShiftsMutationUpdateWorkShiftArgs = {
  id: Scalars['String']
  param: WorkShiftParam
}

export enum DevicesSearchType {
  DeviceId = 'DEVICE_ID',
  Imei = 'IMEI',
  VinNo = 'VIN_NO',
}

export type LastVehicle = {
  ID: Scalars['String']
  name: Scalars['String']
  time: Scalars['String']
  type: Scalars['String']
}

export type LoadingCoordinate = {
  latitude?: Maybe<Scalars['String']>
  longitude?: Maybe<Scalars['String']>
  time?: Maybe<Scalars['String']>
}

export type TerminalParam = {
  id: Scalars['String']
  targetValue: StatusTargetValue
}

export type UpdateManufactureParam = {
  id: Scalars['String']
  status: QcFilterType
}
