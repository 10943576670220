export const authHost = `${process.env.AUTH_HOST}/query`
export const graphQLHost = process.env.GRAPHQL_HOST

export const GRAPHQL_SERVER_HOST = {
  FMS: `${process.env.GRAPHQL_HOST}/query`,
  IDM: `${process.env.GRAPHQL_HOST_IDM}/query`,
}

export const idmHost = process.env.IDM_HOST
export const fmsHost = process.env.FMS_HOST
export const fmsHostAlva = process.env.FMS_HOST_ALVA
export const idmHostAlva = process.env.IDM_HOST_ALVA

export const unleashHost = `${process.env.UNLEASH_HOST}/api/frontend`
export const unleashTokenAPI = process.env.UNLEASH_TOKEN

export const wsHost = process.env.WS_HOST
export const wsToken = process.env.WS_TOKEN
export const wsChannel = process.env.WS_CHANNEL
export const mapsApiKey = process.env.MAPS_API_KEY
export const intervalPopupInMS = parseInt(
  process.env.AUTOCLOSE_IMEI_POPUP_INTERVAL
)

// export const mapUrl = `https://api.maptiler.com/maps/streets/256/{z}/{x}/{y}.png?key=KwWLYSD2MU0b5Rmx2PpM`
export const mapUrl = `https://api.maptiler.com/maps/streets/256/{z}/{x}/{y}.png?key=Wb8xmWI46sZiCkBoggEY`

export const mapSatelite =
  'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}?token=AAPKece0053a7b12411985a0f5b94bb6f7e2B451NPKcV6bvyOQi9av57Ck9KiKujXZJywIFrBEhNxhntjdeAMvsEFoh6I1MvBAB'

export const CHANNEL_KEY = {
  POSITIONS: 'positions',
}

export const graphqlDefaultOptions = {
  pollInterval: 1000 * 60 * 30, // 30 Minutes
  notifyOnNetworkStatusChange: true,
}

export const blacklistedUser = process.env.BLACKLISTED_USER

export const ALLOWED_DOMAINS = [
  'xtrip.link',
  'xapiens.id',
  'alvaauto.com',
  'xtrip.click',
]

export const thisLink = 'kalista'

export const SUBDOMAINS = {
  FLEETO_MAIN_SUBDOMAIN: fmsHost.split('.')[0].replace(/^https?:\/\//, ''),
  FLEETO_ALVA_SUBDOMAIN: fmsHostAlva.split('.')[0].replace(/^https?:\/\//, ''),
}

export const currentSubdomain = window.location.hostname.split('.')[0]

export const currentDomain = window.location.hostname.split('.')[1]

export const DOMAINS_IDM = {
  FLEETO_MAIN_DOMAIN: idmHost?.split('.')[1].replace(/^https?:\/\//, ''),
  FLEETO_ALVA_DOMAIN: idmHostAlva?.split('.')[1].replace(/^https?:\/\//, ''),
}

export const DOMAINS = {
  FLEETO_MAIN_DOMAIN: fmsHost?.split('.')?.[1]?.replace(/^https?:\/\//, ''),
  FLEETO_ALVA_DOMAIN: fmsHostAlva?.split('.')?.[1]?.replace(/^https?:\/\//, ''),
}

export const idmHostMapping = {
  [DOMAINS_IDM.FLEETO_ALVA_DOMAIN as string]: idmHostAlva,
  [DOMAINS_IDM.FLEETO_MAIN_DOMAIN as string]: idmHost,
}

export const redirectIdmHost = idmHostMapping[currentDomain] || idmHost
