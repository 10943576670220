import React from 'react'

import loadable from '@loadable/component'
import { ReactSVG } from 'react-svg'

import { PERMISSIONS } from '~/config/constants'

const DevicesPage = loadable(
  () => import('~/pages/Devices') /* webpackChunkName: "Devices" */
)
const Trip = loadable(
  () => import('~/pages/Trip') /* webpackChunkName: "Trip" */
)
const Historical = loadable(
  () => import('~/pages/Historical') /* webpackChunkName: "Historical" */
)

const CyclePage = loadable(
  () => import('~/pages/Cycle') /* webpackChunkName: "CyclePage" */
)

const Maps = loadable(() => import('~/pages/Map') /* webpackChunkName: "Map" */)

const Tracking = loadable(
  () => import('~/pages/Tracking') /* webpackChunkName: "Tracking" */
)

const Assets = loadable(
  () => import('~/pages/Assets') /* webpackChunkName: "Assets" */
)

const AssetsBus = loadable(
  () => import('~/pages/AssetsBus') /* webpackChunkName: "Assets" */
)

const Groups = loadable(
  () => import('~/pages/Groups') /* webpackChunkName: "Groups" */
)
const Geofence = loadable(
  () => import('~/pages/Geofence') /* webpackChunkName: "Geofence" */
)
const GeofenceAlert = loadable(
  () => import('~/pages/GeofenceAlert') /* webpackChunkName: "GeofenceAlert" */
)
const QualityCheck = loadable(
  () => import('~/pages/QualityCheck') /* webpackChunkName: "QualityCheck" */
)
const ScanIMEIPage = loadable(
  () => import('~/pages/ScanIMEI') /* webpackChunkName: "ScanIMEI" */
)

const ReportBIPage = loadable(
  () => import('~/pages/ReportBI') /* webpackChunkName: "ReportBI" */
)

const ReportBIBusPage = loadable(
  () => import('~/pages/ReportBus') /* webpackChunkName: "ReportBIBus" */
)

const IotCheckAfterSalesPage = loadable(
  () =>
    import(
      '~/pages/IotCheckAfterSales'
    ) /* webpackChunkName: "IotCheckAfterSales" */
)
const PDIPage = loadable(
  () => import('~/pages/PDI') /* webpackChunkName: "PDI" */
)

const IotCheckPDIPage = loadable(
  () => import('~/pages/IotCheckPDI') /* webpackChunkName: "IotCheckPDI" */
)

const Subscriptions = loadable(
  () => import('~/pages/Subscriptions') /* webpackChunkName: "Subscriptions" */
)

const TestHistory = loadable(
  () => import('~/pages/TestHistory') /* webpackChunkName: "TestHistory" */
)

const DriversPage = loadable(
  () => import('~/pages/Drivers') /* webpackChunkName: "TestHistory" */
)

const CommandHistory = loadable(
  () =>
    import('~/pages/CommandHistory') /* webpackChunkName: "CommandHistory" */
)

const AccessHistory = loadable(
  () => import('~/pages/AccessHistory') /* webpackChunkName: "AccessHistory" */
)

const Dashboard = loadable(
  () => import('~/pages/Dashboard') /* webpackChunkName: "Dashboard" */
)

const DashboardChart = loadable(
  () =>
    import('~/pages/DashboardChart') /* webpackChunkName: "DashboardChart" */
)

const HistoricalBus = loadable(
  () => import('~/pages/HistoricalBus') /* webpackChunkName: "HistoricalBus" */
)

export default [
  {
    id: 'productivity',
    title: 'Productivity',
    path: '/productivity',
    defaultPath: '/productivity',
    component: DashboardChart,
    exact: false,
    withHeader: true,
    icon: (
      <ReactSVG
        src="/public/icons/menu-icon/productivity.svg"
        width={24}
        height={24}
      />
    ),
    permission: PERMISSIONS.FMS_READ_PRODUCTIVITY,
  },
  {
    id: 'dashboard',
    title: 'Dashboard',
    path: '/dashboard',
    defaultPath: '/dashboard',
    component: Dashboard,
    exact: false,
    withHeader: true,
    icon: <ReactSVG src="/public/icons/menu-icon/dashboard.svg" />,
    permission: PERMISSIONS.FMS_READ_DASHBOARD,
  },
  {
    id: 'map',
    title: 'Tracking',
    path: '/map',
    defaultPath: '/map',
    component: Maps,
    exact: false,
    withHeader: true,
    icon: <ReactSVG src="/public/icons/menu-icon/map.svg" />,
    permission: PERMISSIONS.FMS_READ_LIVETRACK,
  },
  {
    id: 'tracking',
    title: 'Tracking',
    path: '/tracking',
    defaultPath: '/tracking',
    component: Tracking,
    exact: false,
    withHeader: true,
    icon: <ReactSVG src="/public/icons/menu-icon/map.svg" />,
    permission: PERMISSIONS.FMS_TRACKING_PLUS,
  },
  {
    id: 'playback',
    title: 'Trips',
    path: '/playback/:tab',
    defaultPath: '/playback/trip',
    component: Trip,
    exact: false,
    withHeader: true,
    icon: <ReactSVG src="/public/icons/menu-icon/trip.svg" />,
    permission: PERMISSIONS.FMS_READ_REPORT,
  },
  {
    id: 'historical',
    title: 'Historical PHR',
    path: '/historical',
    defaultPath: '/historical',
    component: Historical,
    exact: false,
    withHeader: true,
    icon: <ReactSVG src="/public/icons/menu-icon/playback.svg" />,
    permission: PERMISSIONS.FMS_PHR_HISTORICAL,
  },
  {
    id: 'historicalBus',
    title: 'Historical',
    path: '/historicalBus',
    defaultPath: '/historicalBus',
    component: HistoricalBus,
    exact: false,
    withHeader: true,
    icon: <ReactSVG src="/public/icons/menu-icon/playback.svg" />,
    permission: PERMISSIONS.FMS_ASSET_PLUS,
  },
  {
    id: 'cycle',
    title: 'Cycles',
    path: '/cycle',
    defaultPath: '/cycle',
    component: CyclePage,
    exact: false,
    withHeader: true,
    icon: <ReactSVG src="/public/icons/menu-icon/cycle.svg" />,
    permission: PERMISSIONS.FMS_READ_ROUTES,
  },
  {
    id: 'devices',
    title: 'Devices',
    path: '/devices',
    defaultPath: '/devices',
    component: DevicesPage,
    exact: false,
    withHeader: true,
    icon: <ReactSVG src="/public/icons/menu-icon/devices.svg" />,
    permission: PERMISSIONS.FMS_READ_DEVICE,
  },
  {
    id: 'drivers',
    title: 'Drivers',
    path: '/drivers',
    defaultPath: '/drivers',
    component: DriversPage,
    exact: false,
    withHeader: true,
    icon: <ReactSVG src="/public/icons/menu-icon/profile.svg" />,
    permission: PERMISSIONS.FMS_READ_DRIVER,
  },
  {
    id: 'subscriptions',
    title: 'Subscriptions',
    path: '/subscriptions',
    defaultPath: '/subscriptions',
    component: Subscriptions,
    exact: false,
    withHeader: true,
    icon: <ReactSVG src="/public/icons/menu-icon/subscriptions.svg" />,
    permission: PERMISSIONS.FMS_READ_SUBSCRIPTIONS,
  },
  {
    id: 'assets',
    title: 'Assets',
    path: '/assets',
    defaultPath: '/assets',
    component: Assets,
    exact: false,
    withHeader: true,
    icon: <ReactSVG src="/public/icons/menu-icon/assets.svg" />,
    permission: PERMISSIONS.FMS_READ_VEHICLE,
  },
  {
    id: 'assets-bus',
    title: 'Assets',
    path: '/assets-bus',
    defaultPath: '/assets-bus',
    component: AssetsBus,
    exact: false,
    withHeader: true,
    icon: <ReactSVG src="/public/icons/menu-icon/bus.svg" />,
    permission: PERMISSIONS.FMS_ASSET_PLUS,
  },
  {
    id: 'pre-delivery',
    title: 'Pre Delivery Inspection',
    path: '/pre-delivery',
    defaultPath: '/pre-delivery',
    component: PDIPage,
    exact: false,
    withHeader: true,
    icon: <ReactSVG src="/public/icons/menu-icon/assets.svg" />,
    permission: PERMISSIONS.FMS_READ_PREDELIVERY_ASSETS,
  },
  {
    id: 'pre-delivery-iot',
    title: 'Iot Check',
    path: '/pre-delivery-iot',
    defaultPath: '/pre-delivery-iot',
    component: IotCheckPDIPage,
    exact: false,
    withHeader: true,
    icon: <ReactSVG src="/public/icons/menu-icon/iotcheck.svg" />,
    permission: PERMISSIONS.FMS_READ_PREDELIVERY_ASSETS,
  },
  {
    id: 'groups',
    title: 'Groups',
    path: '/groups',
    defaultPath: '/groups',
    component: Groups,
    exact: false,
    withHeader: true,
    icon: <ReactSVG src="/public/icons/menu-icon/groups.svg" />,
    permission: PERMISSIONS.READ_ORGANIZATION,
  },
  {
    id: 'geofence',
    title: 'Geofencing',
    path: '/geofence',
    defaultPath: '/geofence',
    component: Geofence,
    exact: false,
    withHeader: true,
    icon: <ReactSVG src="/public/icons/menu-icon/geofencing.svg" />,
    permission: PERMISSIONS.FMS_READ_GEOFENCE,
  },
  {
    id: 'alerts',
    title: 'Alerts',
    path: '/alerts',
    defaultPath: '/alerts',
    component: GeofenceAlert,
    exact: false,
    withHeader: true,
    icon: <ReactSVG src="/public/icons/menu-icon/geo-alert.svg" />,
    permission: PERMISSIONS.FMS_READ_ALERT,
  },
  {
    id: 'qualitycheck',
    title: 'Quality Check',
    path: '/qualitycheck',
    defaultPath: '/qualitycheck',
    component: QualityCheck,
    exact: false,
    withHeader: true,
    icon: <ReactSVG src="/public/icons/menu-icon/qc-icon.svg" />,
    permission: PERMISSIONS.FMS_READ_MANUFACTURE,
  },
  {
    id: 'scan-imei',
    title: 'IoT Check',
    path: '/scan-imei',
    defaultPath: '/scan-imei',
    component: ScanIMEIPage,
    exact: false,
    withHeader: true,
    icon: <ReactSVG src="/public/icons/menu-icon/iotcheck.svg" />,
    permission: PERMISSIONS.FMS_READ_IOT,
  },
  {
    id: 'iot-check-after',
    title: 'IoT Check After Sales',
    path: '/iot-check-after',
    defaultPath: '/iot-check-after',
    component: IotCheckAfterSalesPage,
    exact: false,
    withHeader: true,
    icon: <ReactSVG src="/public/icons/menu-icon/iotcheck.svg" />,
    permission: PERMISSIONS.FMS_READ_IOT_CHECK_AFTER_SALES,
  },
  {
    id: 'access-history',
    title: 'Access History',
    path: '/access-history',
    defaultPath: '/access-history',
    component: AccessHistory,
    exact: false,
    withHeader: true,
    icon: <ReactSVG src="/public/icons/menu-icon/access.svg" />,
    permission: PERMISSIONS.FMS_READ_COMMAND_ACCESS_HISTORY,
  },
  {
    id: 'command-history',
    title: 'Command History',
    path: '/command-history',
    defaultPath: '/command-history',
    component: CommandHistory,
    exact: false,
    withHeader: true,
    icon: <ReactSVG src="/public/icons/menu-icon/command.svg" />,
    permission: PERMISSIONS.FMS_READ_COMMAND_ACCESS_HISTORY,
  },
  {
    id: 'test-history',
    title: 'Test History',
    path: '/test-history',
    defaultPath: '/test-history',
    component: TestHistory,
    exact: false,
    withHeader: true,
    icon: <ReactSVG src="/public/icons/menu-icon/test-histroy.svg" />,
    permission: PERMISSIONS.FMS_READ_TEST_RECORD,
  },
  {
    id: 'report',
    title: 'Report',
    path: '/report',
    defaultPath: '/report',
    component: ReportBIPage,
    exact: false,
    withHeader: true,
    icon: <ReactSVG src="/public/icons/menu-icon/dashboard.svg" />,
    permission: PERMISSIONS.FMS_READ_REPORT_GROUP,
  },
  {
    id: 'report-bus',
    title: 'Report',
    path: '/report-bus',
    defaultPath: '/report-bus',
    component: ReportBIBusPage,
    exact: false,
    withHeader: true,
    icon: <ReactSVG src="/public/icons/menu-icon/dashboard.svg" />,
    permission: PERMISSIONS.FMS_REPORT_KALISTA,
  },
]
